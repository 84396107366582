import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BelegDTO} from '../../openapi/beleg-openapi';

@Injectable({
  providedIn: 'root'
})
export class EditFormService implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private beleg$ = new BehaviorSubject<BelegDTO | undefined>(undefined);

  constructor() {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  set beleg(beleg: BehaviorSubject<BelegDTO | undefined>) {
    this.beleg$ = beleg;
  }

  get beleg(): BehaviorSubject<BelegDTO | undefined> {
    return this.beleg$;
  }
}
