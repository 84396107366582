<form [formGroup]="editForm" autocomplete="off">

  <mat-form-field
    class="{{validationRequestedAnimation ? 'validationRequested' : ''}}"
    appearance="outline"
    (keyup)="formControls.empfaenger.updateValueAndValidity()"
  >
    <mat-label>Empfänger</mat-label>
    <input
      maxlength="70"
      [pattern]="sepaRegex"
      matInput
      formControlName="empfaenger"
      (focusin)="startHighlightFocus('empfaenger')"
      (focusout)="clearHighlighting()"
      (keyup)="startHighlightKeyup('empfaenger')"
    >
    <div class="errors">
      <mat-hint
        *ngIf="
          formControls.empfaenger.touched
          && formControls.empfaenger.errors?.['required']
          && validationRequested"
      >
        Dieses Feld muss ausgefüllt werden.
      </mat-hint>
      <mat-hint
        *ngIf="
          formControls.empfaenger.touched
          && formControls.empfaenger.errors?.['maxlength']
          && validationRequested"
      >
        Dieses Feld darf max. 70 Zeichen enthalten.
      </mat-hint>
      <mat-hint
        *ngIf="
            formControls.empfaenger.touched
            && formControls.empfaenger.errors?.['pattern']"
      >
        Dieses Feld darf nur Buchstaben, Zahlen, Leerzeichen und die Zeichen <span class="group-signs">/ ? : ( ) . , ' + -</span>
        enthalten.
      </mat-hint>
    </div>
  </mat-form-field>

  <mat-form-field
    class="{{validationRequestedAnimation ? 'validationRequested' : ''}}"
    appearance="outline"
  >
    <mat-label>IBAN</mat-label>
    <input
      matInput
      formControlName="iban"
      [matAutocomplete]="autocompleteIban"
      (focusin)="startHighlightFocus('iban')"
      (focusout)="clearHighlighting()"
      (keyup)="startHighlightKeyup('iban')"
    >
    <div class="errors">
      <mat-hint
        *ngIf="formControls.iban.touched
        && formControls.iban.errors?.['required']
        && validationRequested"
      >
        Dieses Feld muss ausgefüllt werden.
      </mat-hint>
      <mat-hint
        *ngIf="formControls.iban.touched
        && formControls.iban.errors?.['iban']
        && validationRequested"
      >
        Ungültiges IBAN-Format.
      </mat-hint>
    </div>

    <mat-autocomplete autoActiveFirstOption #autocompleteIban>

      <mat-option *ngFor="let option of filteredIbanOptions | async" [value]="option.iban">
        <zlng-iban-autocomplete-option
          [iban]="option.iban"
          [bank]="option.bank">
        </zlng-iban-autocomplete-option>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field
    class="{{validationRequestedAnimation ? 'validationRequested' : ''}}"
    appearance="outline"
  >
    <mat-label>Bank</mat-label>
    <input
      matInput
      minlength="1"
      maxlength="60"
      formControlName="bank"
      (focusin)="startHighlightFocus('bank')"
      (focusout)="clearHighlighting()"
      (keyup)="startHighlightKeyup('bank')"
    >
    <div class="errors">
      <mat-hint
        *ngIf="formControls.bank.touched
        && formControls.bank.errors?.['required']
        && validationRequested"
      >
        Dieses Feld muss ausgefüllt werden.
      </mat-hint>
      <mat-hint
        *ngIf="formControls.bank.touched
        && formControls.bank.errors?.['maxlength']"
      >
        Dieses Feld darf max. 60 Zeichen enthalten.
      </mat-hint>
    </div>

    <mat-hint
      *ngIf="
        !(formControls.bank.errors?.['required'] && validationRequested)
        && !formControls.bank.errors?.['minlength']
        && !formControls.bank.errors?.['maxlength']"
    >
      Wird automatisch ausgefüllt
    </mat-hint>
  </mat-form-field>

  <mat-form-field
    class="{{validationRequestedAnimation ? 'validationRequested' : ''}}"
    appearance="outline"
  >
    <mat-label>BIC</mat-label>
    <input
      matInput
      minlength="8"
      maxlength="11"
      formControlName="bic"
      (focusin)="startHighlightFocus('bic')"
      (focusout)="clearHighlighting()"
      (keyup)="startHighlightKeyup('bic')"
    >
    <div class="errors">
      <mat-hint
        *ngIf="formControls.bic.touched
        && formControls.bic.errors?.['required']
        && validationRequested"
      >
        Dieses Feld muss ausgefüllt werden.
      </mat-hint>
      <mat-hint
        *ngIf="formControls.bic.touched
        && (formControls.bic.errors?.['minlength'] || formControls.bic.errors?.['maxlength'])"
      >
        Ungültiges BIC-Format.
      </mat-hint>
    </div>
    <mat-hint
      *ngIf="!(formControls.bic.errors?.['required'] && validationRequested)
                && !formControls.bic.errors?.['minlength']
                && !formControls.bic.errors?.['maxlength']"
    >
      Wird automatisch ausgefüllt
    </mat-hint>
  </mat-form-field>

  <mat-form-field
    class="{{validationRequestedAnimation ? 'validationRequested' : ''}}"
    appearance="outline">
    <mat-label>Zahlbetrag</mat-label>
    <input
      matInput
      currencyMask
      formControlName="betrag"
      [options]="{
        prefix: '',
        suffix: ' €',
        thousands: '.',
        decimal: ',',
        align: 'left',
        inputMode: currencyMaskInputMode,
        allowNegative: false,
        nullable: true,
        min: 0.00,
        max: 9999999.99
      }"
      (focusin)="startHighlightFocus('betrag')"
      (focusout)="clearHighlighting()"
      (keyup)="startHighlightKeyup('betrag')"
    >
    <div class="errors">
      <mat-hint
        *ngIf="formControls.betrag.touched
        && formControls.betrag.errors?.['required']
        && validationRequested
      ">
        Dieses Feld muss ausgefüllt werden.
      </mat-hint>
      <mat-hint
        *ngIf="formControls.betrag.touched
        && formControls.betrag.errors?.['min']
        && validationRequested"
      >
        Der Betrag muss größer als 0 sein.
      </mat-hint>
    </div>
  </mat-form-field>

  <mat-form-field
    class="{{validationRequestedAnimation ? 'validationRequested' : ''}}"
    appearance="outline"
  >
    <mat-label>Verwendungszweck</mat-label>
    <input
      maxlength="140"
      [pattern]="sepaRegex"
      matInput
      formControlName="verwendungszweck"
      (focusin)="startHighlightFocus('verwendungszweck')"
      (focusout)="clearHighlighting()"
      (keyup)="startHighlightKeyup('verwendungszweck')"
    >
    <div class="errors">
      <mat-hint
        *ngIf="
            formControls.verwendungszweck.touched
            && formControls.verwendungszweck.errors?.['maxlength']
            && validationRequested"
      >
        Dieses Feld darf max. 140 Zeichen enthalten.
      </mat-hint>
      <mat-hint
        *ngIf="
            formControls.verwendungszweck.touched
            && formControls.verwendungszweck.errors?.['pattern']"
      >
        Dieses Feld darf nur Buchstaben, Zahlen, Leerzeichen und die Zeichen <span class="group-signs">/ ? : ( ) . , ' + -</span>
        enthalten.
      </mat-hint>
    </div>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Fälligkeitsdatum</mat-label>
    <input
      (blur)="faelligkeitsdatumFocus$.next(false)"
      (focus)="faelligkeitsdatumFocus$.next(true)"
      matInput
      [matDatepicker]="picker"
      formControlName="faelligkeitsdatum"
      min="1900-01-01"
      max="2099-12-31"
      (focusin)="startHighlightFocus('faelligkeitsdatum')"
      (focusout)="clearHighlighting()"
      (keyup)="startHighlightKeyup('faelligkeitsdatum')"
    >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</form>
