import {createReducer, on} from '@ngrx/store';
import {AppContextState} from '../../interfaces/app-context-state.interface';
import {AppContextActions} from '../actions/app-context.actions';


/**
 * Der AppContextState steuert das Verhalten der gesamten App oder einzelner Komponenten der App.
 * Dies abhängig davon, in welchem Kontext diese angezeigt / aufgerufen werden.
 * Beispiele für unterschiedliche Kontexte könnten beispielsweise Standalone, im Just Farming Portal als App
 * oder als eine im Intent aufgerufene Komponente oder im LAND-DATA ADNOVA+ sein.
 */

export const initialAppContextState: AppContextState = {
  popupMode: false,
  wizardActionButtons: [],
};

export const appContextReducer = createReducer(
  initialAppContextState,

  on(
    AppContextActions.setPopupMode,
    (state, action) => ({
      ...state,
      popupMode: action.popupMode,
    })
  ),

  on(
    AppContextActions.setWizardActionButtons,
    (state, action) => ({
      ...state,
      wizardActionButtons: action.wizardActionButtons,
    })
  ),

  on(
    AppContextActions.setLastIntentZahlungUpdated,
    (state, action) => ({
      ...state,
      lastIntentZahlungUpdated: action.lastIntentZahlungUpdated,
    })
  ),

  on(
    AppContextActions.setLastIntentZahlungCreated,
    (state, action) => ({
      ...state,
      lastIntentZahlungCreated: action.lastIntentZahlungCreated,
    })
  ),
);
