import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentBrowserComponent } from './payment-browser.component';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';



@NgModule({
    declarations: [
        PaymentBrowserComponent
    ],
    exports: [
        PaymentBrowserComponent
    ],
    imports: [
        CommonModule,
        MatPaginatorModule
    ]
})
export class PaymentBrowserModule { }
