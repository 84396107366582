import {Component, EventEmitter, OnInit, Output, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {AppState} from '../../interfaces/app-state.interface';
import {BelegSelectors} from '../../store/selectors/beleg.selectors';


@Component({
  selector: 'zlng-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit, OnDestroy {

  protected readonly unsubscribe$ = new Subject<void>();

  @Output()
  closePreview = new EventEmitter();

  public closeable?: boolean;

  public isPdf?: boolean;

  public loading = false;

  constructor(
    protected logger: NGXLogger,
    protected store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.store.select(BelegSelectors.isLoading).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loading => {
      this.loading = loading;
    });

    this.store.select(BelegSelectors.isPdf).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isPdf => {
      this.isPdf = isPdf;
    });

    this.store.select(BelegSelectors.closeable).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(closeable => {
      this.closeable = closeable;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  doClosePreview(): void {
    this.closePreview.emit();
  }
}
