import {createAction, props} from '@ngrx/store';
import {
  BankDTO,
  BankFilterDTO,
  BankingTokenResponseDTO,
  BankkontoCreateRequestDTO,
  BankkontoDTO,
  BankkontoFilterDTO,
  BankkontoPageableDTO,
  KontaktResponseDTO,
  SicherheitsverfahrenDTO, ZahlungDTO, ZahlungslaufApproveRequestDTO,
  ZahlungslaufCreateRequestDTO,
  ZahlungslaufResponseDTO
} from '../../openapi/zahlung-openapi';


export class TransactionActions {

  /*
   * INFO: Transaktion Actions
   */

  public static resetCollectivePreview = createAction(
    '[Payment Browser] Reset collective Preview.',
  );

  public static collectivePreviewBrowse = createAction(
    '[Payment Browser] Changed page index of payment browser.',
    props<{
      page: number,
    }>(),
  );

  public static setFinalZahlungDtos = createAction(
    '[Finalize Component] Set final payments.',
    props<{
      zahlungDtos: ZahlungDTO[],
    }>(),
  );

  public static readUsedAccountBankInfo = createAction(
    '[Used Account Component] Read bank by iban.',
    props<{
      iban: string,
    }>(),
  );

  public static loadedUsedAccountBankInfo = createAction(
    '[Used Account Component] Loaded bank by iban.',
    props<{
      blz?: string,
      bezeichnung: string,
      bic: string,
    }>(),
  );

  public static readBanks = createAction(
    '[Bank Component] Read banks.',
    props<{
      bankFilter: BankFilterDTO,
    }>(),
  );

  public static banksLoaded = createAction(
    '[Transaction Effect] Banks loaded.',
    props<{
      banks: Array<BankDTO>,
    }>(),
  );

  public static selectBank = createAction(
    '[Bank Component] Select bank',
    props<{
      bank: BankDTO | undefined,
    }>()
  );

  public static readUsedAccounts = createAction(
    '[Bank Component] Read the already used accounts',
    props<{
      inhaberId: string,
      filter: BankkontoFilterDTO,
      pageable: BankkontoPageableDTO,
    }>()
  );

  public static usedAccountsLoaded = createAction(
    '[Transaction Effect] Used accounts loaded',
    props<{
      usedAccounts: BankkontoDTO[],
    }>()
  );

  public static getBankingAccessToken = createAction(
    '[Login Component] Request a banking token from bs service.',
    props<{
      bankingTokenIdentifier: string,
    }>(),
  );

  public static bankingAccessTokenAccessed = createAction(
    '[Transaction Effect] Successfully got a banking access token from bs service',
    props<{
      token: BankingTokenResponseDTO,
    }>(),
  );

  public static bankingRefreshTokenAccessed = createAction(
    '[Transaction Effect] Successfully got a banking refresh token from bs service',
    props<{
      token: BankingTokenResponseDTO,
    }>(),
  );

  public static getBankingRefreshToken = createAction(
    '[Login Component] Request a banking token from bs',
    props<{
      token: string,
    }>(),
  );

  public static deleteBankingToken = createAction(
    '[Tan-Enty Component] Delete the banking access/refresh token',
    props<{
      token: string,
    }>(),
  );

  public static allowPollingLogin = createAction(
    '[Login Component] Updates allowPolling for login',
    props<{
      allowed: boolean,
    }>(),
  );

  public static allowPollingTan = createAction(
    '[Login Component] Updates allowPolling for tan',
    props<{
      allowed: boolean,
    }>(),
  );

  public static resetBankingToken = createAction(
    '[Login Component] Resets the bankingToken',
  );

  public static resetTanData = createAction(
    '[Tan-Entry Component] Sets containsTanData to false',
  );

  public static getKontaktInfo = createAction(
    '[Tan Component] Read kontaktInfo.',
    props<{
      token: string,
      clientIp: string,
      inhaberId: string,
      pollingActive: boolean,
      destination: string,
    }>(),
  );

  public static kontaktInfoLoaded = createAction(
    '[Transaction Effect] Securityfunctions loaded.',
    props<{
      response: KontaktResponseDTO,
    }>(),
  );

  public static resetKontaktInfo = createAction(
    '[Tan Component] Resets the kontaktInfo',
  );

  public static selectSecurityfunction = createAction(
    '[Tan Component] Select securityfunction',
    props<{
      securityfunction: SicherheitsverfahrenDTO,
    }>(),
  );

  public static resetSelectedSecurityfunction = createAction(
    '[Login Component] Resets the selected securityfunction',
  );

  public static setSicherheitsverfahren = createAction(
    '[Account Component] Read accounts.',
    props<{
      token: string,
      securityfunction: SicherheitsverfahrenDTO,
      inhaberId: string,
      xs2aTan?: string,
    }>()
  );

  public static selectAccount = createAction(
    '[Account Component] Select account',
    props<{
      account: BankkontoDTO,
    }>()
  );

  public static resetSelectedAccount = createAction(
    '[Login Component] Resets the selected account',
  );

  public static resetXs2aUrl = createAction(
    '[Tan-Entry Component] Resets url',
  );

  public static resetAuthentifizierungsgrund = createAction(
    '[Tan-Entry Component] Resets the authentifizierungsgrund',
  );

  public static assignPayment = createAction(
    '[Finalize Component] Assign payment',
    props<{
      inhaberId: string,
      token: string,
      clientIp: string,
      request: ZahlungslaufCreateRequestDTO,
      pollingActive: boolean,
    }>()
  );

  public static createAccount = createAction(
    '[Finalize Component] Create new account',
    props<{
      inhaberId: string,
      bankkontoCreateRequest: BankkontoCreateRequestDTO,
    }>()
  );

  public static createAccountSuccessful = createAction(
    '[Finalize Component] New account created',
    props<{
      account: BankkontoDTO,
    }>()
  );

  public static createAccountError = createAction(
    '[Finalize Component] Could not create account',
    props<{
      inhaberId: string,
    }>()
  );

  public static selectSecurityFunctionException = createAction(
    '[Transaction Effect] The user needs to select a securityfunction.',
    props<{
      inhaberId: string,
      sicherheitsverfahren: SicherheitsverfahrenDTO[],
      destination: string,
    }>()
  );

  public static assignPaymentSuccessful = createAction(
    '[Transaction Effect] The assignment of the payment was successful.',
    props<{
      inhaberId: string,
      response: ZahlungslaufResponseDTO,
    }>()
  );

  public static strongAuthenticationException = createAction(
    '[Transaction Effect] Handling for strong authentication exception.',
    props<{
      inhaberId: string,
      pollingActive: boolean,
      destination: string,
      authenticationUrl?: string,
      authentifizierungsgrund?: string,
      response?: ZahlungslaufResponseDTO,
      balanceStrongAuthenticationExceptionOccurred: boolean,
    }>()
  );

  public static approvePayment = createAction(
    '[ResultScreen Component] Approves the payment.',
    props<{
      inhaberId: string,
      paymentIds: string[],
      token: string,
      zahlungslaufId: string,
      request: ZahlungslaufApproveRequestDTO,
      pollingActive: boolean,
    }>()
  );

  public static approvePaymentSuccessful = createAction(
    '[ResultScreen Component] The approvement of the payment was successful.',
    props<{
      response: ZahlungslaufResponseDTO,
    }>()
  );

  public static resetZahlungslaufResponse = createAction(
    '[Tan-Entry Component] Resets the ZahlungslaufResponse.',
  );

  public static selectPreview = createAction(
    '[Finalize Component] Select preview payment out of collective transfer.',
    props<{
      zahlungDto: ZahlungDTO,
    }>()
  );

  public static setXs2aTan = createAction(
    '[Xs2a Component] Set xs2a tan (code).',
    props<{
      tan: string,
    }>()
  );

  public static updateBalance = createAction(
    '[Finalize Component] Update balance',
    props<{
      inhaberId: string,
      iban: string,
      token: string,
      clientIp: string
    }>(),
  );

  public static updateBalanceStrong = createAction(
    '[Finalize Component] Update balance strong',
    props<{
      inhaberId: string,
      iban: string,
      token: string,
      securityfunction: SicherheitsverfahrenDTO,
      tan: string,
    }>(),
  );

  public static balanceUpdated = createAction(
    '[Finalize Component] Balance updated',
    props<{
      account: BankkontoDTO,
    }>()
  );

  public static balanceUpdatedStrong = createAction(
    '[Finalize Component] Balance updated strong',
    props<{
      account: BankkontoDTO,
    }>()
  );

  public static resetBalanceStrongAuthenticationExceptionOccurred = createAction(
    '[Finalize Component] Reset balanceStrongAuthenticationExceptionOccurred mark.',
  );

  public static setExecutiondate = createAction(
    '[Finalize Component] Set executiondate',
    props<{
      executiondate?: string,
    }>()
  );

  public static logout = createAction(
    '[Wizard Component] Triggers the logout.',
  );

  /*
   * INFO: Transaktion Failure Actions
   */

  public static openFailureMessageDialog = createAction(
    '[Transaction Components] Open dialog.',
    props<{
      inhaberId: string,
      message: string,
    }>()
  );

  public static closeFailureMessageDialog = createAction(
    '[Failure Message Dialog] Close dialog and route to login page.',
    props<{
      inhaberId: string,
    }>()
  );

  /*
   * INFO: Session Expired Actions
   */

  public static openSessionExpiredDialog = createAction(
    '[Transaction Component] Open dialog.',
    props<{
      inhaberId: string,
    }>()
  );

  public static closeSessionExpiredDialog = createAction(
    '[Transaction Component] Close dialog and route to login page.',
    props<{
      inhaberId: string,
    }>()
  );
}
