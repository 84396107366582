import {NgModule} from '@angular/core';
import {PreloadAllModules, RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {CustomRouteReuseStrategy, ReuseStrategy} from './reuse-strategy';
import {PreviewOrBeleglosComponent} from './modules/preview-or-beleglos/preview-or-beleglos.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'edit/inhaber',
    pathMatch: 'full',
  },
  {
    path: 'overview',
    loadChildren: () => import('./routes/overview-routing.module').then(m => m.OverviewRoutingModule),
    canActivate: [AuthGuard],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'edit',
    loadChildren: () => import('./routes/edit-routing.module').then(m => m.EditRoutingModule),
    canActivate: [AuthGuard],
    data: {
      reuseStrategy: {reuse: [PreviewOrBeleglosComponent.name]} as ReuseStrategy,
    },
  },
  {
    path: 'transaction',
    loadChildren: () => import('./routes/transaction-routing.module').then(m => m.TransactionRoutingModule),
    canActivate: [AuthGuard],
    data: {reuseStrategy: {reuse: [PreviewOrBeleglosComponent.name]} as ReuseStrategy},
  },
  {
    path: 'delete-dialog',
    loadChildren: () => import('./modules/delete-dialog/delete-dialog.module').then(m => m.DeleteDialogModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'beleg-cancelled-modal',
    loadChildren: () => import('./modules/beleg-cancelled-dialog/beleg-cancelled-dialog.module').then(m => m.BelegCancelledDialogModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'impressum',
    loadChildren: () => import('./modules/impressum/impressum.module').then(m => m.ImpressumModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'xs2a/success',
    loadChildren: () => import('./modules/transaction/xs2a/success/success.module').then(m => m.SuccessModule),
  },
  {
    path: 'xs2a/failure',
    loadChildren: () => import('./modules/transaction/xs2a/failure/failure.module').then(m => m.FailureModule),
  },
  {
    path: 'iframe-preview',
    loadChildren: () => import('./modules/iframe-preview-dialog/iframe-preview-dialog.module').then(m => m.IframePreviewDialogModule),
  },
  {
    path: 'widget/utility-widget',
    loadChildren: () => import('./modules/widget/utility-widget/utility-widget.module').then(m => m.UtilityWidgetModule),
    canActivate: [AuthGuard],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: '**',
    redirectTo: 'overview',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadAllModules,
      }
    ),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    }
  ],
})
export class AppRoutingModule {
}
