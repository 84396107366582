import {ZahlungslaufResponseDTO} from '../../openapi/zahlung-openapi';
import {createReducer, on} from '@ngrx/store';
import copy from 'fast-copy';
import {TransactionState} from '../../interfaces/transaction-state.interface';
import {TransactionActions} from '../actions/transaction.actions';


export const initialTransactionState: TransactionState = {
  banks: [],
  usedAccounts: [],
  finalPayments: [],
  finalPaymentCurrent: 0,
  bankingTokenResponse: undefined,
  allowPollingLogin: true,
  allowPollingTan: true,
  accounts: [],
  xs2aTan: '',
  collectivePreviewIndex: 0,
  balanceStrongAuthenticationExceptionOccurred: false,
  failureMessageDialogData: {
    isFailureMessageDialogOpen: false,
  },
  sessionExpiredDialogData: {
    isSessionExpiredDialogOpen: false,
  },
};

export const transactionReducers = createReducer(
  initialTransactionState,

  /*
   * INFO: Transaktion Reducers
   */

  on(TransactionActions.banksLoaded, (state, action) => ({
      ...state,
      banks: action.banks,
    })
  ),

  on(TransactionActions.selectBank, (state, action) => ({
      ...state,
      selectedBank: action.bank,
    })
  ),

  on(TransactionActions.usedAccountsLoaded, (state, action) => ({
      ...state,
      usedAccounts: action.usedAccounts,
    })
  ),

  on(TransactionActions.loadedUsedAccountBankInfo, (state, action) => {
    const newUsedAccounts = copy(state.usedAccounts);

    if (newUsedAccounts && action.blz) {

      for (const usedAccount of newUsedAccounts) {

        if (usedAccount.iban.includes(action.blz)) {

          if (!usedAccount.bic) {
            usedAccount.bic = action.bic;
          }

          if (!usedAccount.bankbezeichnung) {
            usedAccount.bankbezeichnung = action.bezeichnung;
          }
        }
      }
    }

    return {
      ...state,
      usedAccounts: newUsedAccounts
    };
  }),

  on(TransactionActions.setFinalZahlungDtos, (state, action) => {
    return {
      ...state,
      finalPayments: action.zahlungDtos,
      collectivePreview: action.zahlungDtos[0],
      collectivePreviewIndex: 0,
      executiondate: undefined,
    };
  }),

  on(TransactionActions.bankingAccessTokenAccessed, (state, action) => ({
    ...state,
    bankingTokenResponse: action.token,
  })),

  on(TransactionActions.bankingRefreshTokenAccessed, (state, action) => {
    const newToken = {...state.bankingTokenResponse};
    newToken.loginDatenVorhanden = action.token.loginDatenVorhanden;
    newToken.tanDatenVorhanden = action.token.tanDatenVorhanden;

    return {
      ...state,
      bankingTokenResponse: newToken,
      allowPollingLogin: !action.token.loginDatenVorhanden,
      allowPollingTan: !action.token.tanDatenVorhanden,
    };
  }),

  on(TransactionActions.resetBankingToken, (state) => ({
    ...state,
    bankingTokenResponse: {
      token: undefined,
      loginDatenVorhanden: undefined,
      tanDatenVorhanden: undefined,
      clientIp: undefined,
      longTermToken: undefined,
    },
  })),

  on(TransactionActions.resetTanData, (state) => {
    const newToken = {...state.bankingTokenResponse};
    newToken.tanDatenVorhanden = false;

    return {
      ...state,
      bankingTokenResponse: newToken,
    };
  }),

  on(TransactionActions.allowPollingLogin, (state, action) => ({
    ...state,
    allowPollingLogin: action.allowed,
  })),

  on(TransactionActions.allowPollingTan, (state, action) => ({
    ...state,
    allowPollingTan: action.allowed,
  })),

  on(TransactionActions.kontaktInfoLoaded, (state, action) => {
    const accounts = action.response.bankkonten;
    const tanDataExists = accounts && accounts.length !== 0;

    // INFO: Banking-Token aktualisieren, da die Kontaktinformationen durch Eingabe der TAN erhalten worden sind.
    const newToken = {...state.bankingTokenResponse};
    newToken.tanDatenVorhanden = tanDataExists;

    /*
     * INFO: Wenn im Store an diesem Punkt noch kein Sicherheitsverfahren gewählt wurde,
     * soll das Sicherheitsverfahren aus dem Kontakt-Response genommen werden. Dieser Fall kann eintreten,
     * wenn die Bank direkt nach dem Login eine starke Authentifizierung mit XS2A verlangt, ohne das ein
     * Sicherheitsverfahren vom Anwender gewählt werden konnte.
     */
    let updatedSelectedSecurityfunction = state.selectedSecurityfunction;
    if (!updatedSelectedSecurityfunction || updatedSelectedSecurityfunction.id === undefined) {

      const securityFunctions = action.response.sicherheitsverfahren;
      if (securityFunctions) {
        const securityfunctionResponse = securityFunctions[0];

        updatedSelectedSecurityfunction = {
          id: securityfunctionResponse.id,
          bezeichnung: securityfunctionResponse.bezeichnung,
          standard: securityfunctionResponse.standard
        };
      }
    }

    return {
      ...state,
      kontaktInfo: action.response,
      bankingTokenResponse: newToken,
      selectedSecurityfunction: updatedSelectedSecurityfunction,
      xs2aTan: '',

      // INFO: Poll-Timer aktualisieren
      allowPollingTan: !tanDataExists,
    };
  }),

  on(TransactionActions.resetKontaktInfo, (state) => ({
    ...state,
    kontaktInfo: {
      bankkonten: undefined,
      sicherheitsverfahren: undefined,
    },
  })),

  on(TransactionActions.selectSecurityfunction, (state, action) => ({
      ...state,
      selectedSecurityfunction: action.securityfunction,
    })
  ),

  on(TransactionActions.resetSelectedSecurityfunction, (state) => ({
    ...state,
    selectedSecurityfunction: {
      id: undefined,
      bezeichnung: undefined,
      standard: undefined,
    },
  })),

  on(TransactionActions.selectAccount, (state, action) => ({
      ...state,
      selectedAccount: action.account,
    })
  ),

  on(TransactionActions.resetSelectedAccount, (state) => ({
    ...state,
    selectedAccount: {
      iban: '',
      bic: '',
      bankbezeichnung: '',
      id: '',
      inhaberId: '',
      datumKontostand: undefined,
      kontoinhaber: undefined,
      kontostand: undefined,
    },
  })),

  on(TransactionActions.resetXs2aUrl, (state) => ({
      ...state,
      xs2aUrl: undefined,
    })
  ),

  on(TransactionActions.resetAuthentifizierungsgrund, (state) => ({
      ...state,
      authentifizierungsgrund: undefined,
    })
  ),

  on(TransactionActions.createAccountSuccessful, (state, action) => ({
      ...state,
      usedAccounts: [...state.usedAccounts, action.account],
      selectedAccount: action.account,
    })
  ),

  on(TransactionActions.selectSecurityFunctionException, (state, action) => {
    const newKontaktInfo = {...state.kontaktInfo};
    newKontaktInfo.sicherheitsverfahren = action.sicherheitsverfahren;

    return {
      ...state,
      kontaktInfo: newKontaktInfo,
    };
  }),

  on(TransactionActions.setExecutiondate, (state, action) => ({
      ...state,
      executiondate: action.executiondate,
    })
  ),

  on(TransactionActions.assignPaymentSuccessful, (state, action) => ({
      ...state,
      zahlungslaufResponse: action.response,
    })
  ),

  on(TransactionActions.approvePaymentSuccessful, (state, action) => ({
      ...state,
      zahlungslaufResponse: action.response,
      xs2aTan: '',
    })
  ),

  on(TransactionActions.strongAuthenticationException, (state, action) => {
      const newResponse = action.response;
      if (state.zahlungslaufResponse && newResponse) {
        const response: ZahlungslaufResponseDTO = {...state.zahlungslaufResponse};

        response.offen = newResponse.offen;
        response.abgelehnt = newResponse.abgelehnt;
        response.nichtErfolgreich = newResponse.nichtErfolgreich;
        response.erfolgreich = newResponse.erfolgreich;
        response.zahlungslaufId = newResponse.zahlungslaufId;


        return {
          ...state,
          zahlungslaufResponse: response,
          balanceStrongAuthenticationExceptionOccurred: action.balanceStrongAuthenticationExceptionOccurred,
          xs2aUrl: action.authenticationUrl,
          authentifizierungsgrund: action.authentifizierungsgrund,
        };
      }

      return {
        ...state,
        zahlungslaufResponse: action.response,
        balanceStrongAuthenticationExceptionOccurred: action.balanceStrongAuthenticationExceptionOccurred,
        xs2aUrl: action.authenticationUrl,
        authentifizierungsgrund: action.authentifizierungsgrund,
      };
    }
  ),

  on(TransactionActions.resetZahlungslaufResponse, (state) => ({
      ...state,
      zahlungslaufResponse: undefined,
    })
  ),

  on(TransactionActions.selectPreview, (state, action) => ({
      ...state,
      collectivePreview: action.zahlungDto,
    })
  ),

  on(TransactionActions.resetCollectivePreview, (state) => {
      return {
        ...state,
        collectivePreview: undefined,
      };
    }
  ),

  on(TransactionActions.collectivePreviewBrowse, (state, action) => {
      return {
        ...state,
        collectivePreviewIndex: action.page,
      };
    }
  ),

  on(TransactionActions.setXs2aTan, (state, action) => ({
      ...state,
      xs2aTan: action.tan,
    })
  ),

  on(TransactionActions.balanceUpdated, (state, action) => ({
      ...state,
      selectedAccount: action.account,
    })
  ),

  on(TransactionActions.balanceUpdatedStrong, (state, action) => ({
      ...state,
      selectedAccount: action.account,
      balanceStrongAuthenticationExceptionOccurred: false,
    })
  ),

  on(TransactionActions.resetBalanceStrongAuthenticationExceptionOccurred, (state) => ({
      ...state,
      balanceStrongAuthenticationExceptionOccurred: false,
    }),
  ),

  on(TransactionActions.logout, (state) => ({
      ...state,

      bankingTokenResponse: {
        token: undefined,
        loginDatenVorhanden: undefined,
        tanDatenVorhanden: undefined,
        clientIp: undefined,
        longTermToken: undefined,
      },

      selectedSecurityfunction: {
        id: undefined,
        bezeichnung: undefined,
        standard: undefined,
      },

      selectedAccount: {
        iban: '',
        bic: '',
        bankbezeichnung: '',
        id: '',
        inhaberId: '',
        datumKontostand: undefined,
        kontoinhaber: undefined,
        kontostand: undefined,
      },
    })
  ),

  /*
   * INFO: Transaktion Failure Reducers
   */

  on(
    TransactionActions.openFailureMessageDialog,
    (state) => ({
      ...state,
      failureMessageDialogData: {
        ...state.failureMessageDialogData,
        isFailureMessageDialogOpen: true,
      },
    })),

  on(
    TransactionActions.closeFailureMessageDialog,
    (state) => ({
      ...state,
      failureMessageDialogData: {
        ...state.failureMessageDialogData,
        isFailureMessageDialogOpen: false,
      },
    })),

  /*
   * INFO: Session Expired Reducers
   */

  on(
    TransactionActions.openSessionExpiredDialog,
    (state) => ({
      ...state,
      sessionExpiredDialogData: {
        isSessionExpiredDialogOpen: true,
      },
    })),

  on(
    TransactionActions.closeSessionExpiredDialog,
    (state) => ({
      ...state,
      sessionExpiredDialogData: {
        isSessionExpiredDialogOpen: false,
      },
    })),
);
