import {Column} from '../beleg-filter-dialog/beleg-filter-dialog.interfaces';

export const INITIAL_COLUMNS: Column[] = [
  {
    id: 'status',
    name: 'Status',
    isActive: true,
    type: 'status',
  },
  {
    id: 'vorgangsart.bezeichnung',
    name: 'Belegtyp',
    isActive: true,
    type: 'string',
    sortingId: 'Vorgangsart',
    width: 115,
  },
  {
    id: 'partner.bezeichnung',
    name: 'Kunde/Lieferant',
    isActive: true,
    type: 'string',
    sortingId: 'Partnerbezeichnung',
  },
  {
    id: 'belegDatum',
    name: 'Belegdatum',
    isActive: true,
    type: 'date',
    sortingId: 'Datum',
    width: 115,
  },
  {
    id: 'betrag',
    name: 'Betrag',
    isActive: true,
    type: 'currency',
    sortingId: 'Betrag',
    width: 125,
  },
  {
    id: 'belegbucheinAusgang',
    name: 'Ein-/Ausgang',
    isActive: false,
    type: 'belegbucheinAusgang',
    width: 125,
  },
  {
    id: 'kommentar',
    name: 'Kommentar',
    isActive: false,
    type: 'string',
    width: 200,
  },
  {
    id: 'zuordnen',
    name: '',
    isActive: true,
    type: 'zuordnen',
    isAdjustable: false,
  },
  {
    id: 'preview',
    name: '',
    isActive: true,
    type: 'preview',
    isAdjustable: false,
  },
];
