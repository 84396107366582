import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../interfaces/app-state.interface';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {Router} from '@angular/router';


@Component({
  selector: 'zlng-deleted-dialog',
  templateUrl: './deleted-dialog.component.html',
  styleUrls: ['./deleted-dialog.component.scss']
})
export class DeletedDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) private action: { inhaberId: string; zahlungId: string; type: string; },
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  backToOverview(): void {
    const inhaberId = this.action.inhaberId;
    this.store.dispatch(ZahlungActions.openOverviewPage({inhaberId}));
    this.store.dispatch(ZahlungActions.closeZahlungDeletedDialog());
  }

  newPayment(): void {
    const inhaberId = this.action.inhaberId;
    this.store.dispatch(ZahlungActions.resetActualZahlung());
    this.store.dispatch(ZahlungActions.setActualZahlungNull());
    this.router.navigate(['/edit/inhaber/' + inhaberId]);
    this.store.dispatch(ZahlungActions.closeZahlungDeletedDialog());
  }
}
