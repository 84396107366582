import {Component, Input, OnInit} from '@angular/core';
import {getColorByBlz} from '../../account-colors';
import {IbanPipe} from '../../pipes/iban-pipe/iban.pipe';

@Component({
  selector: 'zlng-iban-autocomplete-option',
  templateUrl: './iban-autocomplete-option.component.html',
  styleUrls: ['./iban-autocomplete-option.component.scss'],
  providers: [IbanPipe]
})
export class IbanAutocompleteOptionComponent implements OnInit {

  @Input()
  public blz = undefined;

  @Input()
  public bank = '';

  @Input()
  public iban = '';

  public identificatorColor = '';

  public showArrow = false;

  constructor(
    private ibanPipe: IbanPipe,
  ) {
  }

  ngOnInit(): void {
    this.identificatorColor = getColorByBlz(this.blz);

    this.iban = this.ibanPipe.transform(this.iban);
  }

}
