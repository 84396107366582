import {NGXLogger} from 'ngx-logger';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {Router} from '@angular/router';
import {EditFormService} from '../modules/edit-form/edit-form.service';
import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {BelegService} from './beleg.service';
import {Store} from '@ngrx/store';
import * as moment from 'moment/moment';
import {AppState} from '../interfaces/app-state.interface';
import {AppContextActions} from '../store/actions/app-context.actions';
import {ZahlungActions} from '../store/actions/zahlung.actions';
import {ZahlungSelectors} from '../store/selectors/zahlung.selectors';
import {take} from 'rxjs/operators';
import {NavigationService} from '@adnova/jf-ng-components';


export interface OverviewOpenDataV1 {
  readonly inhaberId: string;
}

export interface EditZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
}

export interface NewZahlungDataV1 {
  readonly inhaberId: string;
  readonly belegId: string;
}

export interface DeleteZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
}

export interface UpdatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface CreatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface DeletedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  /**
   * Intent-Id für den Aufruf der Overview-Ansicht
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_overview_open_v1.schema.json
   *
   * @private
   */
  private readonly overviewOpenIdV1 = 'de.just-farming:zahlungen:overview.open';

  /**
   * Intent-Id für den Aufruf der Edit Seite für eine bestehende Zahlung in einem Popup
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_popup_edit_v1.schema.json
   *
   * @private
   */
  private readonly editOpenPopupIdV1 = 'de.just-farming:zahlungen:popup.edit';

  /**
   * Intent-Id für den Aufruf der Edit Seite für eine bestehende Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_edit_open_v1.schema.json
   *
   * @private
   */
  private readonly editOpenIdV1 = 'de.just-farming:zahlungen:edit.open';

  /**
   * Intent-Id für den Aufruf der Edit Seite für eine neue Zahlung in einem Popup
   *
   * JSON Schema: de.just-farming.zahlungen_popup_edit_new_v1.schema.json
   *
   * @private
   */
  private readonly newZahlungPopupIdV1 = 'de.just-farming:zahlungen:new';

  /**
   * Intent-Id für den Aufruf der Löschen-Seite in einem Popup
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_popup_delete_v1.schema.json
   *
   * @private
   */
  private readonly deleteZahlungPopupIdV1 = 'de.just-farming:zahlungen:popup.delete';

  /**
   * Intent-Id für den Broadcast zur Information über eine aktualisierte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.updated_v1.schema.json
   *
   * @private
   */
  private readonly updatedZahlungIdV1 = 'de.just-farming:zahlungen:payment.updated';

  /**
   * Intent-Id für den Broadcast zur Information über eine erstellte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.created_v1.schema.json
   *
   * @private
   */
  private readonly createdZahlungIdV1 = 'de.just-farming:zahlungen:payment.created';

  /**
   * Intent-Id für den Broadcast zur Information über eine gelöschte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.deleted_v1.schema.json
   *
   * @private
   */
  private readonly deletedZahlungIdV1 = 'de.just-farming:zahlungen:payment.deleted';

  constructor(
    private editFormService: EditFormService,
    private belegService: BelegService,
    private store: Store<AppState>,
    private logger: NGXLogger,
    private portalMessageBroker: PortalMessageBroker,
    private router: Router,
    private navigationService: NavigationService,
  ) {
    if (this.isRunningInPortal()) {
      // open overview page in-app
      this.portalMessageBroker.registerIntentCallback(this.overviewOpenIdV1, '1', (data => {
        this.handleOverviewOpenV1(data);
      }));

      // open edit page in-app with existing payment
      this.portalMessageBroker.registerIntentCallback(this.editOpenIdV1, '1', (data => {
        this.handleEditZahlungV1(data);
      }));

      // open edit page as a popup with existing payment
      this.portalMessageBroker.registerIntentCallback(this.editOpenPopupIdV1, '1', (data => {
        this.handleEditZahlungPopupV1(data);
      }));

      // open edit page with new payment
      this.portalMessageBroker.registerIntentCallback(this.newZahlungPopupIdV1, '1', (data => {
        this.handleNewZahlungV1(data);
      }));

      // open overview page
      this.portalMessageBroker.registerIntentCallback(this.deleteZahlungPopupIdV1, '1', (data => {
        this.handleDeleteV1(data);
      }));

      // created payment
      this.portalMessageBroker.registerIntentCallback(this.createdZahlungIdV1, '1', (data => {
        this.handlePaymentCreatedV1(data);
      }));

      // updated payment
      this.portalMessageBroker.registerIntentCallback(this.updatedZahlungIdV1, '1', (data => {
        this.handlePaymentUpdatedV1(data);
      }));

      // deleted payment
      this.portalMessageBroker.registerIntentCallback(this.deletedZahlungIdV1, '1', (data => {
        this.handlePaymentDeletedV1(data);
      }));

      this.portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  handleOverviewOpenV1(
    data: OverviewOpenDataV1
  ): void {
    const inhaberId = data.inhaberId;

    this.router.navigate(['/overview/inhaber/' + inhaberId]);
  }

  handleEditZahlungV1(
    data: EditZahlungDataV1
  ): void {
    const inhaberId = data.inhaberId;
    const zahlungId = data.zahlungId;

    this.router.navigate(['/edit/inhaber/' + inhaberId + '/zahlung/' + zahlungId]).then(() => {
      this.store.dispatch(AppContextActions.setPopupMode({popupMode: false}));
    });
  }

  handleEditZahlungPopupV1(
    data: EditZahlungDataV1
  ): void {
    const inhaberId = data.inhaberId;
    const zahlungId = data.zahlungId;

    this.router.navigate(
      [
        '/edit/inhaber/' + inhaberId + '/zahlung/' + zahlungId
      ]
    ).then(() => {
      this.store.dispatch(AppContextActions.setPopupMode({popupMode: true}));
    });
  }

  handleNewZahlungV1(
    data: NewZahlungDataV1
  ): void {
    const inhaberId = data.inhaberId;

    this.router.navigate(
      [
        '/edit/inhaber/' + inhaberId
      ]
    ).then(() => {
      this.store.dispatch(AppContextActions.setPopupMode({popupMode: true}));
      const belegId = data.belegId;

      if (belegId) {
        this.belegService.getBeleg(inhaberId, belegId).subscribe(belegDto => {
          this.store.dispatch(ZahlungActions.assignBelegToZahlung({
            belegDto,
          }));
        });
      }
    });
  }

  handleDeleteV1(
    data: DeleteZahlungDataV1
  ): void {
    this.store.select(ZahlungSelectors.zahlungDeleteDialogOpen).subscribe(open => {
      if (open) {
        this.router.navigate(
          [
            '/delete-dialog/inhaber/' + data.inhaberId + '/zahlung/' + data.zahlungId
          ]
        );
      }
    });

    this.store.dispatch(ZahlungActions.openZahlungDeleteEmbedded({
      inhaberId: data.inhaberId,
      zahlungId: data.zahlungId,
    }));
  }

  /**
   * Prüft, ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this.portalMessageBroker.isRunningInPortal();
  }

  /**
   * Öffnet über einen Intent die Übersicht-Seite mit der Tabelle der Zahlungen
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doOverviewOpenV1(
    data: OverviewOpenDataV1,
  ): void {
    this.doEmit(this.overviewOpenIdV1, '1', data);
  }

  /**
   * Öffnet über einen Intent die Bearbeiten-Seite zu einer Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doEditOpenV1(
    data: EditZahlungDataV1,
  ): void {
    this.doEmit(this.editOpenIdV1, '1', data);
  }

  /**
   * Informiert andere Apps über die Aktualisierung einer Zahlung per Broadcast Hintergrund-Intent
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doZahlungUpdatedV1(
    data: UpdatedZahlungDataV1,
  ): void {
    this.doEmit(this.updatedZahlungIdV1, '1', data);
  }

  /**
   * Informiert andere Apps über das Erstellen einer Zahlung per Broadcast Hintergrund-Intent
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doZahlungCreatedV1(
    data: CreatedZahlungDataV1,
  ): void {
    this.doEmit(this.createdZahlungIdV1, '1', data);
  }

  /**
   * Informiert andere Apps über das Löschen einer Zahlung per Broadcast Hintergrund-Intent
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doPaymentDeletedV1(
    data: DeletedZahlungDataV1,
  ): void {
    this.doEmit(this.deletedZahlungIdV1, '1', data);
  }

  /**
   * Schließt ein Intent Popup
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   */
  public doCloseDialogV1(): void {
    this.doClose();
  }

  private doClose(): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.closeIntent();
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doClose(): handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doClose(): closing failed', error);
        },
      );
  }

  handlePaymentCreatedV1(
    data: UpdatedZahlungDataV1
  ): void {
    this.logger.info('Info über erstellte Zahlung ist in Zahlung angekommen:', data);
    this.store.dispatch(AppContextActions.setLastIntentZahlungCreated({
      lastIntentZahlungCreated: moment().toISOString()
    }));

    this.store.dispatch(ZahlungActions.forceReloadUtilityWidget());
  }

  handlePaymentUpdatedV1(
    data: UpdatedZahlungDataV1
  ): void {
    this.logger.info('Info über aktualisierte Zahlung ist in Zahlung angekommen:', data);
    this.store.dispatch(AppContextActions.setLastIntentZahlungUpdated({
      lastIntentZahlungUpdated: moment().toISOString()
    }));

    this.store.dispatch(ZahlungActions.forceReloadUtilityWidget());
  }


  handlePaymentDeletedV1(
    data: UpdatedZahlungDataV1
  ): void {
    this.logger.info('Info über löschen einer Zahlung ist in Zahlung angekommen:', data);

    /*
     * INFO: Wenn der aktuell angezeigte Inhaber gleich dem Inhaber der gelöschten Zahlung ist, soll die Tabelle neu
     * geladen werden.
     */
    const currentInhaberId = this.navigationService.currentInhaber?.id;
    if (currentInhaberId === data.inhaberId) {
      this.store.dispatch(ZahlungActions.reloadZahlungDtos());

      /*
       * INFO: Wenn die aktuell angezeigte Zahlung gelöscht wurde, soll eine Overlay-Meldung angezeigt werden, die den
       * User darauf hinweist. Die aktuell ausgewählte Zahlung kann nicht mehr weiter bearbeitet werden, da diese ja
       * gelöscht wurde.
       */
      this.router.routerState.root.firstChild?.firstChild?.paramMap.pipe(
        take(1),
      ).subscribe(paramMap => {
        const zahlungId = paramMap.get('zahlungId');
        if (data.zahlungenIds.find(id => id === zahlungId)) {
          this.store.dispatch(ZahlungActions.openZahlungDeletedDialog({
            inhaberId: currentInhaberId,
            zahlungId: zahlungId || '',
          }));
        }
      });
    }


    // INFO: Das Badge soll in jedem Fall aktualisiert werden, da es immer angezeigt wird.
    this.store.dispatch(ZahlungActions.countBadgeValue({inhaberId: data.inhaberId}));

    //INFO: Auch das Widget soll in jedem Fall alle Daten aktualisieren.
    this.store.dispatch(ZahlungActions.forceReloadUtilityWidget());
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}
