import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, filter, take, takeUntil} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {FormComponent} from './form/form.component';
import {EditFormService} from './edit-form.service';
import {select, Store} from '@ngrx/store';
import {
  ZahlungCreateRequestDTO,
  ZahlungDTO,
  ZahlungService,
  ZahlungUpdateRequestDTO
} from 'src/app/openapi/zahlung-openapi';
import {WizardActionButton} from '../../templates/wizard/wizard.component';
import {IntentActionService} from '../../services/intent.action.service';
import {AppState} from '../../interfaces/app-state.interface';
import {AppContextSelectors} from '../../store/selectors/app-context.selectors';
import {AppContextActions} from '../../store/actions/app-context.actions';
import {TransactionActions} from '../../store/actions/transaction.actions';
import {ZahlungSelectors} from '../../store/selectors/zahlung.selectors';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {BelegActions} from '../../store/actions/beleg.actions';
import {InhaberEntitiesSelectors} from '@adnova/jf-ng-components';


@Component({
  selector: 'zlng-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})
export class EditFormComponent implements OnInit, OnDestroy {

  private _zahlungId = new BehaviorSubject('');

  private _inhaberId = new BehaviorSubject('');

  private readonly unsubscribe$ = new Subject<void>();

  private wizardActionButtons = new BehaviorSubject<WizardActionButton[]>([]);

  private popupMode = false;

  private sepaSymbols = /[^0-9a-zA-Z\/?:().,'+ -]/g;

  public angewiesen?: boolean;

  public isNotEditable?: boolean;

  public isBelegCancelled?: boolean;

  @ViewChild('formComponent')
  formComponent!: FormComponent;

  constructor(
    private editFormService: EditFormService,
    private zahlungService: ZahlungService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private intentActionService: IntentActionService,
  ) {
  }

  ngOnInit(): void {
    /*
     * INFO: Sofern die Bearbeiten-Seite aufgerufen wird, müssen die
     * - StartTransaction
     * - closeEditPage
     * - saveActionSuccessed
     * Kennzeichen zurückgesetzt werden.
     * Ist dies nicht der fall, so würde nach dem erstmaligen Ausführen einer Zahlung hier immer direkt auf die
     * Bankauswahl-Seite oder auf die Übersicht navigiert werden.
     */
    this.store.dispatch(ZahlungActions.resetEditPageOnInit());
    this.store.dispatch(TransactionActions.setFinalZahlungDtos({zahlungDtos: []}));

    // Action Buttons für die Navigation an den Header senden
    this.wizardActionButtons.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(buttons => {
      this.store.dispatch(AppContextActions.setWizardActionButtons({
        wizardActionButtons: buttons,
      }));
    });

    this.store.select(ZahlungSelectors.filterStatusOpen).pipe(
      take(1),
    ).subscribe(filterStatusOpen => {
      const status = filterStatusOpen ? 'open' : 'all';
      this.wizardActionButtons.next([
        {
          title: 'Zur Übersicht',
          href: `/overview/inhaber/`,
          icon: 'common:arrow_left',
          showInPopup: false,
          queryParams: {status},
        },
      ]);
    });

    this.route.paramMap.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      paramMap => {
        this.zahlungId = paramMap.get('zahlungId') || '';
      }
    );

    this.store.select(InhaberEntitiesSelectors.currentInhaber).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if (!inhaber) return;

      this.inhaberId = inhaber.id;

      this.wizardActionButtons.next(this.wizardActionButtons.value.map(button => {
        const newButton = {...button};
        if (newButton.title === 'Zur Übersicht') {
          newButton.href += this.inhaberId;
        }
        return newButton;
      }));
    });

    combineLatest([
      this._inhaberId,
      this._zahlungId,
    ]).pipe(
      filter(([inhaberId, zahlungId]) => !!inhaberId && !!zahlungId),
      takeUntil(this.unsubscribe$),
    ).subscribe(([inhaberId, zahlungId]) => {
      this.store.dispatch(
        ZahlungActions.getZahlung({inhaberId, zahlungId})
      );
    });

    this.store.select(ZahlungSelectors.belegId)
      .pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(belegId => {
      if (belegId && !this.angewiesen && this.inhaberId) {
        this.store.dispatch(BelegActions.getBelegSuggestions({
          inhaberId: this.inhaberId,
          belegId,
        }));
      }
    });

    this.editFormService.beleg.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(beleg => {
      if (beleg) {
        const empfaengerDto = {
          id: beleg.partner?.id,
          bezeichnung: beleg.partner?.bezeichnung,
        };

        this.store.dispatch(ZahlungActions.changedFormValues({
          empfaengerDto,
          iban: beleg.iban,
          betrag: beleg.betrag,
          verwendungszweck: beleg.verwendungszweck,
          faelligkeitsdatum: beleg.faelligkeitsdatum,
        }));
      }
    });

    this.store.select(ZahlungSelectors.iban).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(iban => {
      if (iban.length <= 8) return;

      this.store.dispatch(
        ZahlungActions.readBank({
          iban,
        })
      );
    });

    this.store.select(ZahlungSelectors.assignedBelegStatusCancelled).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cancelled => this.isBelegCancelled = cancelled);

    combineLatest([
      this.store.select(ZahlungSelectors.zahlungDto),
      this.store.select(ZahlungSelectors.startTransaction),
      this.store.select(ZahlungSelectors.saveActionSuccessedOrNotNeeded),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([
                   zahlungDto,
                   shouldStartTransaction,
                   saveSuccessed,
                 ]) => {

      // INFO: ZahlungId aktualisieren, da eine Zahlung neu erstellt oder verändert worden sein kann
      if (!this.zahlungId && zahlungDto) {
        this.zahlungId = zahlungDto.id;
      }

      if (shouldStartTransaction && saveSuccessed && zahlungDto) {
        this.doTransfer();
      }
    });

    this.store.select(AppContextSelectors.popupMode).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(popupMode => {
      this.popupMode = popupMode;
    });

    combineLatest([
      this.store.select(ZahlungSelectors.closeEditPage),
      this.store.select(ZahlungSelectors.saveActionSuccessedOrNotNeeded),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([close, saveSuccessed]) => {
      if (close && saveSuccessed) {
        if (this.popupMode && this.intentActionService.isRunningInPortal()) {
          this.intentActionService.doCloseDialogV1();
        } else {
          this.router.navigateByUrl('/overview/inhaber/' + this.inhaberId);
        }
      }
    });

    this.store.pipe(
      select(ZahlungSelectors.angewiesen),
      takeUntil(this.unsubscribe$),
    ).subscribe(angewiesen => {
      this.angewiesen = angewiesen;
    });

    this.store.pipe(
      select(ZahlungSelectors.notEditable),
      takeUntil(this.unsubscribe$),
    ).subscribe(isNotEditable => {
      this.isNotEditable = isNotEditable;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get inhaberId(): string {
    return this._inhaberId.getValue();
  }

  set inhaberId(inhaberId: string) {
    this._inhaberId.next(inhaberId);
  }

  get zahlungId(): string {
    return this._zahlungId.getValue();
  }

  set zahlungId(zahlungId: string) {
    this._zahlungId.next(zahlungId);
  }

  saveZahlung(shouldStartTransaction: boolean): void {
    if (!this.formComponent.valid) {
      return;
    }

    const zahlungId = this.zahlungId;

    if (zahlungId) {
      this.updateZahlung(zahlungId, shouldStartTransaction);
    } else {
      this.createZahlung(shouldStartTransaction);
    }
  }

  private createZahlung(shouldStartTransaction: boolean): void {
    if (!this.inhaberId) return;

    const zahlungCreateRequestDto = this.formComponent.createRequestDTO();

    this.store.dispatch(ZahlungActions.createZahlung({
      inhaberId: this.inhaberId,
      request: {
        ...zahlungCreateRequestDto,
        verwendungszweck: zahlungCreateRequestDto.verwendungszweck?.replace(this.sepaSymbols, ''),
        empfaenger: {
          ...zahlungCreateRequestDto.empfaenger,
          bezeichnung: zahlungCreateRequestDto.empfaenger.bezeichnung?.replace(this.sepaSymbols, ''),
        },
      },
      startTransaction: shouldStartTransaction,
    }));
  }

  private updateZahlung(zahlungId: string, shouldStartTransaction: boolean): void {
    const inhaberId = this.inhaberId;
    const requestDto = this.formComponent.createRequestDTO();

    this.store.select(ZahlungSelectors.actualZahlungDto)
      .pipe(
        take(1),
      ).subscribe(actualZahlung => {

      // INFO: Prüfen, ob sich die Daten der Zahlung nicht verändert haben oder die Zahlung nicht verändert werden kann
      if (this.hasZahlungChanged(actualZahlung, requestDto) || this.isNotEditable) {

        // INFO: Kein Speichern nötig, da sich die Daten der Zahlung nicht verändert haben
        this.store.dispatch(ZahlungActions.updateZahlungNotNeeded({
          startTransaction: shouldStartTransaction
        }));

      } else if (inhaberId) {
        // INFO: Speichern nötig, da sich die Daten der Zahlung verändert haben
        this.store.dispatch(ZahlungActions.updateZahlung({
          inhaberId,
          zahlungId,
          requestDto,
          startTransaction: shouldStartTransaction,
        }));
      }
    });
  }

  /**
   * Vergleicht die aktuellen Daten der Zahlung, mit denen welche ursprünglich von der DB geladen wurden.
   *
   * @param actualzahlung Die ursprünglichen Zahlungsdaten
   * @param requestDto Die neuen Daten der Zahlung
   * @private
   */
  private hasZahlungChanged(
    actualzahlung: ZahlungDTO | undefined,
    requestDto: ZahlungCreateRequestDTO | ZahlungUpdateRequestDTO
  ): boolean | undefined {

    return actualzahlung
      && actualzahlung.empfaenger.bezeichnung === requestDto.empfaenger.bezeichnung
      && actualzahlung?.iban === requestDto.iban
      && actualzahlung.betrag === requestDto.betrag
      && actualzahlung.verwendungszweck === requestDto.verwendungszweck
      && actualzahlung.faelligkeitsdatum === requestDto.faelligkeitsdatum
      && actualzahlung.bic === requestDto.bic
      && actualzahlung.bankbezeichnung === requestDto.bankbezeichnung
      && actualzahlung.belegId === requestDto.belegId;
  }

  doTransfer(): void {
    this.store.select(ZahlungSelectors.zahlungDto).pipe(
      take(1),
    ).subscribe(zahlungDto => {
      if (!zahlungDto) return;

      if (!zahlungDto.id) {
        this.snackbar.open(
          'Es gab einen Fehler beim Laden der Zahlung.',
          undefined,
          {duration: 5000, panelClass: 'error'}
        );
        return;
      }

      this.store.dispatch(TransactionActions.setFinalZahlungDtos({zahlungDtos: [zahlungDto]}));

      this.router.navigateByUrl('transaction/inhaber/' + this.inhaberId + '/bank');
    });
  }

  doHighlight(words: string[]): void {
    this.store.dispatch(BelegActions.highlightWords({words}));
  }
}
