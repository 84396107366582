/**
 * Belege
 * API für Belege
 *
 * The version of the OpenAPI document: 1.8.0-FEATURE-jf-anforderungen-SNAPSHOT
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Workflow eines Belegs mit einer Zahlung, sonst immer abgeschlossen 
 */
export enum BelegRechnungsworkflowDTO {
    InErfassung = 'InErfassung',
    Ueberweisen = 'Ueberweisen',
    Abgeschlossen = 'Abgeschlossen'
};

