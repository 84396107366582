import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {AppState} from '../../interfaces/app-state.interface';
import {WidgetHeaderData} from '../../modules/widget/widget-header/widget-header-data';


export class ZahlungSelectors {

  private static select = {
    zahlungDtos: (state: AppState) => state.zahlungReducer.zahlungDtos,
    zahlungenCounted: (state: AppState) => state.zahlungReducer.zahlungenCounted,
    sorting: (state: AppState) => state.zahlungReducer.sorting,
    searchValue: (state: AppState) => state.zahlungReducer.filterSuche,
    pageSize: (state: AppState) => state.zahlungReducer.pageSize,
    pageIndex: (state: AppState) => state.zahlungReducer.pageIndex,
    filterStatusOpen: (state: AppState) => state.zahlungReducer.filterStatusOpen,
    zahlungDeleteDialogData: (state: AppState) => state.zahlungReducer.zahlungDeleteDialogData,
    badgeValues: (state: AppState) => state.zahlungReducer.badgeValues,
    checkedZahlungen: (state: AppState) => state.zahlungReducer.checkedZahlungDtos,
    reloadZahlungen: (state: AppState) => state.zahlungReducer.reloadZahlungen,
    zahlungDto: (state: AppState) => state.zahlungReducer.zahlungDto,
    actualZahlung: (state: AppState) => state.zahlungReducer.actualZahlungDto,
    assignedBelegStatusCancelled: (state: AppState) => state.zahlungReducer.assignedBelegDto?.storniert,
    startTransaction: (state: AppState) => state.zahlungReducer.startTransaction,
    closeEditPage: (state: AppState) => state.zahlungReducer.closeEditPage,
    saveActionSuccessedOrNotNeeded: (state: AppState) => state.zahlungReducer.saveActionSuccessedOrNotNeeded,
    belegSuggestedIbans: (state: AppState) => state.zahlungReducer.belegSuggestedIbans,
    suggestedIbansBankInfo: (state: AppState) => state.zahlungReducer.suggestedIbansBankInfo,
    suggestedPurpose: (state: AppState) => state.zahlungReducer.suggestedPurpose,
    bankinfoBank: (state: AppState) => state.zahlungReducer.bankinfoBank,
    bankinfoBic: (state: AppState) => state.zahlungReducer.bankinfoBic,
    bankinfoLoading: (state: AppState) => state.zahlungReducer.bankinfoLoading,
    utilityWidgetData: (state: AppState) => state.zahlungReducer.utilityWidgetData,
  };

  /*
   * INFO: Zahlung Data
   */

  public static zahlungInhaberId = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.inhaberId || null,
  );

  public static zahlungBelegId = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.belegId || null,
  );

  public static belegId = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.belegId,
  );

  public static empfaenger = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.empfaenger,
  );

  public static empfaengerbezeichnung = createSelector(
    this.select.zahlungDto,
    zahlung => {
      if (zahlung?.empfaenger) {
        return zahlung?.empfaenger.bezeichnung;
      }

      return '';
    },
  );

  public static empfaengerId = createSelector(
    this.select.zahlungDto,
    zahlung => {
      if (zahlung?.empfaenger) {
        return zahlung?.empfaenger.id;
      }

      return '';
    },
  );

  public static iban = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.iban || '',
  );

  public static bank = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.bankbezeichnung || '',
  );

  public static bic = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.bic || '',
  );

  public static betrag = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.betrag,
  );

  public static verwendungszweck = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.verwendungszweck || '',
  );

  public static faelligkeitsdatum = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.faelligkeitsdatum,
  );

  public static angewiesen = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.angewiesen,
  );

  /*
   * INFO: Zahlung Anzahl Laden Selectors
   */

  public static zahlungDto = createSelector(
    this.select.zahlungDto,
    identity,
  );

  public static zahlungDtos = createSelector(
    this.select.zahlungDtos,
    identity,
  );

  public static zahlungDtosCounted = createSelector(
    this.select.zahlungenCounted,
    identity,
  );

  public static reloadZahlungDtos = createSelector(
    this.select.reloadZahlungen,
    identity,
  );

  /*
   * INFO: Zahlung Löschen Selectors
   */

  public static zahlungDeleteDialogInhaberId = createSelector(
    this.select.zahlungDeleteDialogData,
    data => data.inhaberId,
  );

  public static zahlungDeleteDialogZahlungId = createSelector(
    this.select.zahlungDeleteDialogData,
    data => data.zahlungId,
  );

  public static zahlungDeleteSuccessful = createSelector(
    this.select.zahlungDeleteDialogData,
    data => data.deleteSuccessful,
  );

  public static zahlungDeleteFailureInfo = createSelector(
    this.select.zahlungDeleteDialogData,
    data => data.deleteFailureInfo,
  );

  public static zahlungDeleteDialogOpen = createSelector(
    this.select.zahlungDeleteDialogData,
    data => data.isDeleteDialogOpen,
  );

  /*
   * INFO: Zahlung Table Selectors
   */

  public static sorting = createSelector(
    this.select.sorting,
    identity,
  );

  public static searchValue = createSelector(
    this.select.searchValue,
    identity,
  );

  public static pageSize = createSelector(
    this.select.pageSize,
    identity,
  );

  public static pageIndex = createSelector(
    this.select.pageIndex,
    identity,
  );

  public static filterStatusOpen = createSelector(
    this.select.filterStatusOpen,
    identity,
  );

  /*
   * INFO: Zahlung Utility Selectors
   */

  public static badgeValues = createSelector(
    this.select.badgeValues,
    identity,
  );

  public static checkedZahlungDtos = createSelector(
    this.select.checkedZahlungen,
    identity,
  );

  /*
   * INFO: Zahlung Edit-Form Selectors
   */

  public static notEditable = createSelector(
    this.select.zahlungDto,
    zahlung => zahlung?.nichtEditierbar,
  );

  public static actualZahlungDto = createSelector(
    this.select.actualZahlung,
    identity,
  );

  public static assignedBelegStatusCancelled = createSelector(
    this.select.assignedBelegStatusCancelled,
    identity,
  );

  public static startTransaction = createSelector(
    this.select.startTransaction,
    identity,
  );

  public static closeEditPage = createSelector(
    this.select.closeEditPage,
    identity,
  );

  public static saveActionSuccessedOrNotNeeded = createSelector(
    this.select.saveActionSuccessedOrNotNeeded,
    identity,
  );

  public static belegSuggestedIbans = createSelector(
    this.select.belegSuggestedIbans,
    identity,
  );

  public static suggestedIbansBankInfo = createSelector(
    this.select.suggestedIbansBankInfo,
    identity,
  );

  public static suggestedPurpose = createSelector(
    this.select.suggestedPurpose,
    identity,
  );

  public static bankinfoBank = createSelector(
    this.select.bankinfoBank,
    identity,
  );

  public static bankinfoBic = createSelector(
    this.select.bankinfoBic,
    identity,
  );

  public static bankinfoLoading = createSelector(
    this.select.bankinfoLoading,
    identity,
  );

  /*
   * Utility-Widget Selectors
   */

  private static _widgetHeaderDataMemory?: WidgetHeaderData;
  public static widgetHeaderData = createSelector(
    this.select.utilityWidgetData,
    data => {
      if (JSON.stringify(data.widgetHeaderData) !== JSON.stringify(this._widgetHeaderDataMemory)) {
        this._widgetHeaderDataMemory = data.widgetHeaderData;
      }
      return this._widgetHeaderDataMemory;
    },
  );

  public static widgetHeaderDataCreated = createSelector(
    this.select.utilityWidgetData,
    data => data.widgetHeaderDataCreated,
  );

  public static zahlungenOverdueData = (inhaberId: string) => createSelector(
    this.select.utilityWidgetData,
    data => {
      return data?.widgetData?.find(data => data.inhaberId === inhaberId)?.zahlungenOverdueData;
    }
  );

  public static zahlungenOverdueLoading = (inhaberId: string) => createSelector(
    this.select.utilityWidgetData,
    data => {
      return data.widgetData?.find(data => data.inhaberId === inhaberId)?.zahlungenOverdueLoading;
    }
  );

  public static zahlungenNextSevenDaysData = (inhaberId: string) => createSelector(
    this.select.utilityWidgetData,
    data => {
      return data.widgetData?.find(data => data.inhaberId === inhaberId)?.zahlungenNextSevenDaysData;
    }
  );

  public static zahlungenNextSevenDaysLoading = (inhaberId: string) => createSelector(
    this.select.utilityWidgetData,
    data => {
      return data.widgetData?.find(data => data.inhaberId === inhaberId)?.zahlungenNextSevenDaysLoading;
    }
  );

  public static forceReload = createSelector(
    this.select.utilityWidgetData,
    data => data.forceReload,
  );
}
