<div class="wizard-wrapper">
  <div
    class="wizard-header"
    *ngIf="showHeader"
  >
    <!-- Collected Payments Browser -->
    <div *ngIf="finalPayments.length > 1" class="payment-browser" >
      <zlng-payment-browser></zlng-payment-browser>
    </div>

    <div class="nav-buttons">
      <ng-container *ngFor="let actionButton of actionButtons; index as i;">
        <button
          mat-flat-button
          routerLink="{{actionButton.href}}"
          [queryParams]="actionButton.queryParams"
          (click)="doLogout(actionButton.logout)"
        >
          <zlng-icon *ngIf="actionButton.icon" [icon]="actionButton.icon" primaryFill="black"></zlng-icon>
          {{actionButton.title}}
        </button>

        <div *ngIf="i+1 < actionButtons.length" class="divider"></div>
      </ng-container>
    </div>
  </div>

  <div class="wizard-left-content">
    <router-outlet name="left"></router-outlet>
  </div>

  <div class="wizard-right-content">
    <router-outlet name="right"></router-outlet>
  </div>
</div>