<div class="info-wrapper">
  <div>
    <div class="title">Beleg zwischenzeitlich storniert</div>
    <div class="message">
      Bitte wähle, ob die Zahlung gelöscht oder ein neuer Beleg zugeordnet werden soll.
    </div>
    <div class="button-wrapper">
      <button mat-stroked-button
              class="assign-btn"
              color="accent"
              (click)="openEdit()">Neu zuordnen
      </button>

      <button mat-stroked-button
              color="accent"
              (click)="deleteZahlung()">Löschen
      </button>

      <button mat-flat-button class="cancel-btn" (click)="closeDialog()">Abbrechen</button>
    </div>
  </div>
</div>
