import {NgModule} from '@angular/core';
import {IbanAutocompleteOptionComponent} from './iban-autocomplete-option.component';
import {CircleIdentificatorModule} from "../circle-identificator/circle-identificator.module";
import {IconModule} from "../icon/icon.module";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    IbanAutocompleteOptionComponent,
  ],
  imports: [
    CircleIdentificatorModule,
    IconModule,
    CommonModule
  ],
  exports: [
    IbanAutocompleteOptionComponent,
  ]
})
export class IbanAutocompleteOptionModule {

}
