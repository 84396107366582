import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeletedDialogComponent} from './deleted-dialog.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';


@NgModule({
  declarations: [
    DeletedDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
  ]
})
export class DeletedDialogModule {
}
