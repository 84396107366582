import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Store} from '@ngrx/store';

import {BehaviorSubject, combineLatest, merge, Subject} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {BelegService, FilterBelegDTO, PageableBelegDTO, PageableBelegDTOSortingEnum} from '../../openapi/beleg-openapi';
import {Sorting} from '../beleg-filter-dialog/beleg-filter-dialog.interfaces';
import {Actions, ofType} from '@ngrx/effects';
import {INITIAL_COLUMNS} from './beleg-filter-dialog-columns';
import {ZahlungDTO} from '../../openapi/zahlung-openapi';
import {ActivatedRoute} from '@angular/router';
import {AppContextSelectors} from '../../store/selectors/app-context.selectors';
import {AppState} from '../../interfaces/app-state.interface';
import {TransactionSelectors} from '../../store/selectors/transaction.selectors';
import {ZahlungSelectors} from '../../store/selectors/zahlung.selectors';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {BelegSelectors} from '../../store/selectors/beleg.selectors';
import {BelegActions} from '../../store/actions/beleg.actions';
import {InhaberEntitiesSelectors} from '@adnova/jf-ng-components';


@Component({
  selector: 'zlng-preview-or-beleglos',
  templateUrl: './preview-or-beleglos.component.html',
  styleUrls: ['./preview-or-beleglos.component.scss'],
})
export class PreviewOrBeleglosComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private inhaberId = '';

  public hasBeleg?: boolean;

  public popupMode?: boolean;

  public inTransactionWizard = false;

  public angewiesen?: boolean;

  public isNotEditable?: boolean;

  public collectivePreview = new BehaviorSubject<{
    isCollectivePreview?: boolean;
    zahlungDto?: ZahlungDTO;
  }>({});

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private belegService: BelegService,
    private actions$: Actions,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.store.select(ZahlungSelectors.zahlungInhaberId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;

      this.inhaberId = inhaberId;
    });

    this.store.select(AppContextSelectors.popupMode).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(popupMode => {
      this.popupMode = popupMode;
    });

    this.store.select(TransactionSelectors.finalPayments).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(finalPayments => {
      this.inTransactionWizard = !!finalPayments?.length;
    });

    this.store.select(ZahlungSelectors.angewiesen).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(angewiesen => {
      this.angewiesen = angewiesen;
    });

    this.store.select(ZahlungSelectors.notEditable).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isNotEditable => {
      this.isNotEditable = isNotEditable;
    });

    /*
     * INFO: Zusammenfassen der Werte der Selektoren zu einem Objekt.
     * Änderung der BelegId oder des CollectivePreview sollen beide
     * unabhängig voneinander Einfluss auf die Anzeige des Belegs in der Preview haben.
     */
    merge(
      this.store.select(ZahlungSelectors.zahlungBelegId),
      this.store.select(TransactionSelectors.collectivePreview),
    ).pipe(
      takeUntil(this.unsubscribe$),
      filter(data => {
        /*
         * INFO: Wenn data ein Objekt ist und kein (neues) Payment enthält,
         * kann der aktuelle Beleg in der Preview beibehalten werden.
         */
        if (data && typeof (data) === 'object' && !data.zahlungDto) {
          return false;
        }

        return true;
      }),
      map(data => {
        // INFO: Wenn data bereits ein zusammengeführtes Objekt ist, kann es einfach weitergereicht werden
        if (data && typeof (data) === 'object') return data;

        /*
         * INFO: Ansonsten beinhaltet data, die BelegId, welche bisher als String weitergereicht worden ist.
         * Ist dies der Fall, muss dieser dann in das erwartete Format gebracht werden.
         */
        return {
          isCollectivePreview: false,
          zahlungDto: {
            belegId: data,
            inhaberId: this.inhaberId,
          },
        } as {
          isCollectivePreview: boolean,
          zahlungDto: ZahlungDTO,
        };
      }),
    ).subscribe(data => {
      // INFO: Nur wenn sich die BelegId geändert hat, das CollectivPreview aktualisieren
      if (data.zahlungDto?.belegId !== this.collectivePreview.getValue().zahlungDto?.belegId) {
        this.collectivePreview.next(data);
      }
    });

    // INFO: Entscheiden, ob und wie die Preview angezeigt werden soll.
    this.collectivePreview.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(paymentData => {
      if (paymentData && paymentData.isCollectivePreview) {
        if (paymentData.zahlungDto?.inhaberId && paymentData.zahlungDto?.belegId) {
          this.hasBeleg = true;

          // FIXME Errorhandling und in einen Effekt auslagern
          this.belegService.getBeleg(
            paymentData.zahlungDto.inhaberId,
            paymentData.zahlungDto.belegId,
            ).subscribe(belegDto => {
            this.store.dispatch(BelegActions.loadContent({
              belegDto,
              closeable: false,
            }));
          });
        } else {
          this.hasBeleg = false;
          this.store.dispatch(BelegActions.resetBelegPreview());
        }

      } else if (paymentData && !paymentData.isCollectivePreview) {

        if (paymentData.zahlungDto?.inhaberId && paymentData.zahlungDto.belegId) {
          this.hasBeleg = true;

          // FIXME Errorhandling und in einen Effekt auslagern
          this.belegService.getBeleg(
            paymentData.zahlungDto.inhaberId,
            paymentData.zahlungDto.belegId,
          ).subscribe(belegDto => {

            this.store.dispatch(BelegActions.loadContent({
              belegDto,
              closeable: false,
            }));

            /*
             * INFO: Sollte die Zahlung bereits von Anfang an mit einem Beleg verknüpft sein,
             * so muss die Edit-Form Component über das initiale Laden des Belegs informiert werden.
             */
            this.store.dispatch(ZahlungActions.initialAssignedBelegLoaded({
              belegDto,
            }));
          });
        } else {
          this.hasBeleg = false;
          this.store.dispatch(BelegActions.resetBelegPreview());
        }

      } else if (paymentData && paymentData.zahlungDto === null) {
        this.hasBeleg = false;

      } else {
        this.hasBeleg = undefined;
      }
    });

    // INFO: Wenn ein Beleg zugeordnet wurde, wird der Dialog geschlossen.
    this.store.select(BelegSelectors.belegToAssignSelected).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(belegDto => {
      if (!belegDto) return;

      this.store.select(BelegSelectors.isBelegFilterDialogOpen).pipe(
        take(1),
        filter(isOpen => isOpen),
      ).subscribe(() => {
        this.store.dispatch(ZahlungActions.assignBelegToZahlung({
          belegDto,
        }));
        this.store.dispatch(BelegActions.closeBelegFilterDialog());
      });
    });

    // TODO: Das Öffnen des Dialogs sollte in dem Effect openBelegFilterDialog passieren.
    // INFO: Action => Popup öffnen
    this.store.select(BelegSelectors.isBelegFilterDialogOpen).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isOpen => {

      if (isOpen) {
        /*
       * INFO: Lazy-Loading des Moduls in den Dialog.
       * Das zu ladende Modul muss "public static components" enthalten.
       * Näheres zur Lösung hier:
       * http://cv.co.hu/csabi/lazy-loading-angular-material-dialog-content.html
       */
        import('../beleg-filter-dialog/beleg-filter-dialog.module')
          .then((m) => m.BelegFilterDialogModule)
          .then((m) => {
            m.components.BelegFilterDialogComponent.prototype.initialColumns =
              INITIAL_COLUMNS;
            const dialogRef = this.dialog.open(m.components.BelegFilterDialogComponent, {
              disableClose: false,
              minWidth: '850px',
              maxWidth: '1200px',
              width: '80%',
              height: '80%',
              autoFocus: false,
              panelClass: 'beleg-filter-dialog',
            });

            dialogRef.afterClosed().subscribe(() => {
              this.store.dispatch(BelegActions.closeBelegFilterDialog());
            });
          })
          .then(() => {
            setTimeout(() => {
              /*
               * INFO: Wenn das Popup geöffnet wird, werden initial die Filter und die Pagination zurückgesetzt.
               * Das geschieht hier und nicht in der Komponente selbst, damit es perspektivisch möglich ist,
               * das Popup auch mit (von außen) voreingestellten Filtern zu öffnen.
               */
              this.initDialog();
            }, 0);
          });
      }
    });

    // INFO: Action => Popup Schließen
    this.actions$
      .pipe(
        ofType(BelegActions.closeBelegFilterDialog),
        takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.dialog.closeAll();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initDialog(): void {
    // INFO: Aktuelle InhaberID und Pagination auslesen. Anschließend die passenden Belege laden.
    this.store
      .select(InhaberEntitiesSelectors.currentInhaberId)
      .pipe(take(1))
      .subscribe((inhaberId) => {
        combineLatest([
          this.store.select(
            BelegSelectors.pagination
          ),
          this.store.select(BelegSelectors.filter),
          this.store.select(BelegSelectors.sorting),
        ])
          .pipe(takeUntil(this.actions$.pipe(ofType(BelegActions.closeBelegFilterDialog))))
          .subscribe(([pagination, belegFilter, sorting]) => {
            if(!inhaberId) return;

            // INFO: Filter setzen
            const filterBelegDTO: FilterBelegDTO = {
              textfilter: belegFilter.text?.split(' ') || undefined,
              datumVon: belegFilter.dateVon || undefined,
              datumBis: belegFilter.dateBis || undefined,
              betragVon: belegFilter.betragVon || undefined,
              betragBis: belegFilter.betragBis || undefined,
              offen: true,
              bearbeitet: true,
              festgeschrieben: true,
            };

            // INFO: Pagination setzen
            const pageableBelegDTO: PageableBelegDTO = {
              offset: pagination.offset,
              limit: pagination.size,
              sorting: this.sorting2enum(sorting),
            };

            // TODO: Das sollte in einen Effect, ausserdem fehlt Errorhandling
            const countBelege = this.belegService.countBelege(
              inhaberId,
              filterBelegDTO
            );

            // TODO: Das sollte in einen Effect, ausserdem fehlt Errorhandling
            const readBelege = this.belegService.readBelege(
              inhaberId,
              filterBelegDTO,
              pageableBelegDTO
            );

            combineLatest([countBelege, readBelege]).subscribe(
              ([count, belege]) => {
                // INFO: Geladene Belege dispatchen
                this.store.dispatch(
                  BelegActions.loadData({data: belege, count: count.count})
                );
              }
            );
          });
      });
  }

  /**
   * Gibt ein Array von PageableBelegDTOSortingEnums aus
   * @param sorting Die Sortierung aus dem Store der BelegFilterDialog-Komponente
   */
  sorting2enum(sorting: Sorting): PageableBelegDTOSortingEnum[] {
    const enums: PageableBelegDTOSortingEnum[] = [];

    this.store
      .select(BelegSelectors.columns)
      .pipe(take(1))
      .subscribe((columns) => {
        for (let i = 0; i < sorting.sortParams.length; i++) {
          const sortingKey = columns.filter(
            (column) => column.id === sorting.sortParams[i]
          )[0].sortingId;
          const enumKey = sortingKey + sorting.sortDirs[i];
          const enumValue =
            PageableBelegDTOSortingEnum[
              enumKey as keyof typeof PageableBelegDTOSortingEnum
              ];
          enums.push(enumValue);
        }
      });

    return enums;
  }

  openBelegChooser(): void {
    this.store.dispatch(BelegActions.openBelegFilterDialog());
  }

  removeBelegAssociation(): void {
    this.store.dispatch(ZahlungActions.removeBelegAssociation());
  }
}
