<div class="btn-group" role="group" *ngIf="!inTransactionWizard">

  <button mat-button class="assign-new-beleg-button" (click)="assignNewBeleg()">
    <zlng-icon
      icon="common:circled_plus"
      primaryStroke="black"
      class="chain-link"
    ></zlng-icon>
    <span class="assign-new-beleg-button-text">Neue Belegzuweisung</span>
  </button>

  <button
    mat-button
    class="remove-beleg-association-button"
    (click)="removeBelegAssociation()"
  >

    <zlng-icon
      icon="common:broken_chain_link"
      primaryStroke="black"
      class="x-icon"
    ></zlng-icon>

    <span class="remove-beleg-association-button-text">Zuordnung lösen</span>
  </button>
</div>
