import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {IntentActionService} from '../../services/intent.action.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {AppState} from '../../interfaces/app-state.interface';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {ZahlungSelectors} from '../../store/selectors/zahlung.selectors';


@Component({
  selector: 'zlng-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit, OnDestroy {

  public showDeleteWrapper = false;

  public paymentDeleteFailureInfo = '';

  private readonly unsubscribe$ = new Subject<void>();

  private inhaberId = '';

  private zahlungId = '';

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private intentActionService: IntentActionService,
  ) {
  }

  ngOnInit(): void {

    this.store.pipe(
      select(ZahlungSelectors.zahlungDeleteDialogInhaberId),
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;

      this.inhaberId = inhaberId;
    });

    this.store.pipe(
      select(ZahlungSelectors.zahlungDeleteDialogZahlungId),
      takeUntil(this.unsubscribe$),
    ).subscribe(zahlungId => {
      if (!zahlungId) return;

      this.zahlungId = zahlungId;
    });

    this.store.pipe(
      select(ZahlungSelectors.zahlungDeleteSuccessful),
      filter(value => value !== undefined),
      takeUntil(this.unsubscribe$),
    ).subscribe(success => {
      if (success) {
        this.closeDialog();
      } else {
        this.showDeleteWrapper = true;
      }
    });

    this.store.pipe(
      select(ZahlungSelectors.zahlungDeleteFailureInfo),
      takeUntil(this.unsubscribe$),
    ).subscribe(info => {
      if (!info) return;

      this.paymentDeleteFailureInfo = info;
    });

    this.store.select(ZahlungSelectors.zahlungDeleteDialogOpen).pipe(
      filter(isOpen => !isOpen),
      takeUntil(this.unsubscribe$),
    ).subscribe(isOpen => {
      if (!isOpen) {
        if (this.intentActionService.isRunningInPortal()) {
          this.intentActionService.doCloseDialogV1();
        }
      }
    });

    this.route.paramMap.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(paramMap => {
      const inhaberId = paramMap.get('inhaberId') || '';
      const zahlungId = paramMap.get('zahlungId') || '';
      if (inhaberId && zahlungId) {
        this.store.dispatch(ZahlungActions.updateZahlungDeleteDialogData({
          inhaberId,
          zahlungId,
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  deleteZahlung(): void {
    this.store.dispatch(ZahlungActions.zahlungDelete({
      inhaberId: this.inhaberId,
      zahlungId: this.zahlungId,
    }));
  }

  closeDialog(): void {
    this.store.dispatch(ZahlungActions.closeZahlungDeleteDialog());
  }
}
