<!-- Headline -->
<div class="form-header">
  <h1 class="title">{{ !angewiesen ? 'Überweisung erstellen' : 'Überweisung angewiesen' }}</h1>
  <div
    *ngIf="isNotEditable"
    class="not-editable-hint"
  >
    <div class="not-editable-icon-wrapper">
      <zlng-icon icon="common:not_editable" primaryStroke="black"></zlng-icon>
    </div>
    <div>
      Diese Überweisung ist nicht bearbeitbar. Sie wurde in ADNOVA+ erstellt und kann nur dort verändert werden.
    </div>
  </div>

  <div
    *ngIf="isBelegCancelled"
    class="not-editable-hint"
  >
    <div class="not-editable-icon-wrapper">
      <zlng-icon icon="common:beleg-cancelled" primaryStroke="black" accentStroke="red"></zlng-icon>
    </div>
    <div>
      Dieser Beleg wurde storniert. Bitte wähle, ob der Beleg neu zugeordnet oder die Zahlung gelöscht werden soll.
    </div>
  </div>
</div>


<!-- Actual Form -->
<div class="form-content">
  <zlng-form
    #formComponent
    (highlight)="doHighlight($event)"
  ></zlng-form>
</div>

<!-- Action Buttons -->
<div class="form-footer">
  <jf-button
    *ngIf="!angewiesen"
    [jf-tooltip]="isBelegCancelled ? 'Zahlungen mit storniertem Beleg können nicht überwiesen werden.' : ''"
    variant="primary"
    icon="arrowRight"
    [disabled]="isBelegCancelled"
    (click)="saveZahlung(true)"
  >
    Überweisen
  </jf-button>
  <jf-button
    variant="ghost"
    *ngIf="!angewiesen && !isNotEditable"
    [jf-tooltip]="isBelegCancelled ? 'Zahlungen mit storniertem Beleg können nicht überwiesen werden.' : ''"
    (click)="saveZahlung(false)"
  >
    Speichern und später überweisen
  </jf-button>
</div>
