import {createAction, props} from '@ngrx/store';
import {WizardActionButton} from '../../templates/wizard/wizard.component';


export class AppContextActions {

  public static setPopupMode = createAction(
    '[Intent Action Service] Change popup mode',
    props<{
      popupMode: boolean,
    }>(),
  );

  public static setWizardActionButtons = createAction(
    '[Transaction Wizard] Set wizard action buttons',
    props<{
      wizardActionButtons: WizardActionButton[],
    }>(),
  );

  public static setLastIntentZahlungUpdated = createAction(
    '[Intent Action Service] Set date when last PaymentUpdated intent received',
    props<{
      lastIntentZahlungUpdated: string,
    }>(),
  );

  public static setLastIntentZahlungCreated = createAction(
    '[Intent Action Service] Set date when last PaymentCreated intent received',
    props<{
      lastIntentZahlungCreated: string,
    }>(),
  );
}

