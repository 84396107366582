import {createSelector} from '@ngrx/store';
import {AppState} from '../../interfaces/app-state.interface';
import {identity} from 'rxjs';


export class TransactionSelectors {

  private static select = {
    transactionReducer: (state: AppState) => state.transactionReducer,
    banks: (state: AppState) => state.transactionReducer.banks,
    selectedBank: (state: AppState) => state.transactionReducer.selectedBank,
    usedAccounts: (state: AppState) => state.transactionReducer.usedAccounts,
    finalPayments: (state: AppState) => state.transactionReducer.finalPayments,
    kontaktInfo: (state: AppState) => state.transactionReducer.kontaktInfo,
    selectedSecurityfunction: (state: AppState) => state.transactionReducer.selectedSecurityfunction,
    selectedAccount: (state: AppState) => state.transactionReducer.selectedAccount,
    bankingTokenResponse: (state: AppState) => state.transactionReducer.bankingTokenResponse,
    allowPollingLogin: (state: AppState) => state.transactionReducer.allowPollingLogin,
    allowPollingTan: (state: AppState) => state.transactionReducer.allowPollingTan,
    xs2aUrl: (state: AppState) => state.transactionReducer.xs2aUrl,
    xs2aTan: (state: AppState) => state.transactionReducer.xs2aTan,
    authentifizierungsgrund: (state: AppState) => state.transactionReducer.authentifizierungsgrund,
    zahlungslaufResponse: (state: AppState) => state.transactionReducer?.zahlungslaufResponse,
    collectivePreviewIndex: (state: AppState) => state.transactionReducer.collectivePreviewIndex,
    balanceStrongAuthenticationExceptionOccurred: (state: AppState) => state.transactionReducer.balanceStrongAuthenticationExceptionOccurred,
    executiondate: (state: AppState) => state.transactionReducer.executiondate,
    failureMessageDialogData: (state: AppState) => state.transactionReducer.failureMessageDialogData,
  };

  /*
   * INFO: Transaktion Selectors
   */

  public static banks = createSelector(
    this.select.banks,
    identity,
  );

  public static selectedBank = createSelector(
    this.select.selectedBank,
    identity,
  );

  public static usedAccounts = createSelector(
    this.select.usedAccounts,
    identity,
  );

  public static finalPayments = createSelector(
    this.select.finalPayments,
    identity,
  );

  public static securityfunctions = createSelector(
    this.select.kontaktInfo,
    kontaktInfo => kontaktInfo?.sicherheitsverfahren,
  );

  public static selectedSecurityfunction = createSelector(
    this.select.selectedSecurityfunction,
    identity,
  );

  public static accountGuardCanActivate = createSelector(
    this.select.selectedSecurityfunction,
    this.select.xs2aUrl,
    (selectedSecurityfunction, xs2aUrl) => ({
      selectedSecurityfunction,
      xs2aUrl,
    }),
  );

  public static selectedSecurityfunctionId = createSelector(
    this.select.selectedSecurityfunction,
    selectedSecurityfunction => selectedSecurityfunction?.id,
  );

  public static accounts = createSelector(
    this.select.kontaktInfo,
    kontaktInfo => kontaktInfo?.bankkonten,
  );

  public static selectedAccount = createSelector(
    this.select.selectedAccount,
    identity,
  );

  public static bankingToken = createSelector(
    this.select.bankingTokenResponse,
    bankingToken => bankingToken?.token,
  );

  public static containsLoginData = createSelector(
    this.select.bankingTokenResponse,
    bankingToken => bankingToken?.loginDatenVorhanden,
  );

  public static containsTanData = createSelector(
    this.select.bankingTokenResponse,
    bankingToken => bankingToken?.tanDatenVorhanden,
  );

  public static clientIp = createSelector(
    this.select.bankingTokenResponse,
    bankingToken => bankingToken?.clientIp,
  );

  public static allowPollingLogin = createSelector(
    this.select.allowPollingLogin,
    identity,
  );

  public static allowPollingTan = createSelector(
    this.select.allowPollingTan,
    identity,
  );

  public static xs2aUrl = createSelector(
    this.select.xs2aUrl,
    identity,
  );

  public static xs2aTan = createSelector(
    this.select.xs2aTan,
    identity,
  );

  public static authentifizierungsgrund = createSelector(
    this.select.authentifizierungsgrund,
    identity,
  );

  public static zahlungslauf = createSelector(
    this.select.zahlungslaufResponse,
    identity,
  );

  public static zahlungslaufId = createSelector(
    this.select.zahlungslaufResponse,
    zahlungslaufResponse => zahlungslaufResponse?.zahlungslaufId,
  );

  public static sitzungsschluessel = createSelector(
    this.select.zahlungslaufResponse,
    zahlungslaufResponse => zahlungslaufResponse?.sitzungsschluessel,
  );

  public static auftragsId = createSelector(
    this.select.zahlungslaufResponse,
    zahlungslaufResponse => zahlungslaufResponse?.auftragsId,
  );

  public static zahlungslaufSuccess = createSelector(
    this.select.zahlungslaufResponse,
    zahlungslaufResponse => zahlungslaufResponse?.erfolgreich,
  );

  public static zahlungslaufAbgelehnt = createSelector(
    this.select.zahlungslaufResponse,
    zahlungslaufResponse => zahlungslaufResponse?.abgelehnt,
  );

  public static zahlungslaufErgebnistext = createSelector(
    this.select.zahlungslaufResponse,
    zahlungslaufResponse => zahlungslaufResponse?.ergebnistext,
  );

  public static requiredTransactionData = createSelector(
    this.select.transactionReducer,
    transactionReducer => {
      return {
        bankingTokenResponse: transactionReducer.bankingTokenResponse,
        selectedSecurityfunction: transactionReducer.selectedSecurityfunction,
        selectedAccount: transactionReducer.selectedAccount,
      };
    },
  );

  public static collectivePreview = createSelector(
    this.select.transactionReducer,
    transactionReducer => {
      return {
        isCollectivePreview: transactionReducer.collectivePreview !== undefined,
        zahlungDto: transactionReducer.collectivePreview,
      };
    },
  );

  public static collectivePreviewIndex = createSelector(
    this.select.collectivePreviewIndex,
    identity,
  );

  public static balanceStrongAuthenticationExceptionOccurred = createSelector(
    this.select.balanceStrongAuthenticationExceptionOccurred,
    identity,
  );

  public static executiondate = createSelector(
    this.select.executiondate,
    identity,
  );

  /*
   * INFO: Transaktion Failure
   */

  public static isFailureMessageDialogOpen = createSelector(
    this.select.failureMessageDialogData,
    data => data.isFailureMessageDialogOpen,
  );
}
