/**
 * Zahlung
 * Die API ermöglicht es, Zahlungen zu erstellen, zu aktualisieren, zu bearbeiten und zu löschen. Darüber hinaus können Zahlungen ausgeführt werden. Hierbei fungiert der Zahlung-Service als Schnittstelle zum Banking-Service. Der Banking-Service ist gekapselt für die Kommunikation mit unserem Dienstleister B+S zuständig. <br><br> Um eine Zahlung auszuführen muss zuerst mit `getToken` ein Token für den Zahlungsverkehr geholt werden. Dieses Token wird für die Kommunikation mit B+S benötigt. <br> Anschließend kann mit `getKontaktInfo` die Kontaktinformationen zu einem Login abgerufen werden. Hierbei kann es sein, dass ein Sicherheitsverfahren ausgewählt werden muss. In diesem Fall muss mit `setSicherheitsverfahren` das Sicherheitsverfahren gesetzt werden. <br> Dann kann `assignPayment` aufgerufen werden. In diesem Zuge wird die Zahlung durch B+S bei der Bank bekannt gemacht. Die Antwort von B+S wird dann an den Zahlung-Service zurückgegeben. Gegebenenfalls muss der Anwender eine starke Authentifizierung durchführen. <br> Anschließend kann die Zahlung mit `approvePayment` bestätigt werden. Soll die Zahlung doch nicht durchgeführt werden, kann sie mit `cancelPayment` abgelehnt werden. <br><br> Im Rahmen des Durchführens von Zahlung(en) wird ein Zahlungslauf erstellt, welcher diese protokolliert. <br><br> Die API bietet mit die Möglichkeit Bankkonten anzulegen und deren Kontostände zu aktualisieren. <br><br> Es kann nach nach Banken im SEPA Raum gesucht werden und die in der Vergangenheit verwendeten Banken können abgefragt werden. 
 *
 * The version of the OpenAPI document: 1.1.3
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BankingFehlerResponseDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';
import { SicherheitsverfahrenResponseDTO } from '../model/models';
import { StarkeAuthentifizierungResponseDTO } from '../model/models';
import { ZahlungslaufApproveRequestDTO } from '../model/models';
import { ZahlungslaufCancelRequestDTO } from '../model/models';
import { ZahlungslaufCreateRequestDTO } from '../model/models';
import { ZahlungslaufDTO } from '../model/models';
import { ZahlungslaufResponseDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ZahlungslaufService {

    protected basePath = 'https://api.adnova.de/zahlung-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * In Auftrag geben eines Zahlungslaufs
     * Bestätigt die Zahlung bei B+S und aktualisiert anschließend die Daten zu dem Zahlungslauf 
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param zahlungslaufId UUID die einen Zahlungslauf identifziert
     * @param zahlungBankingToken Token für den Zahlungsverkehr
     * @param zahlungslaufApproveRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvePayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungslaufApproveRequestDTO: ZahlungslaufApproveRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ZahlungslaufResponseDTO>;
    public approvePayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungslaufApproveRequestDTO: ZahlungslaufApproveRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ZahlungslaufResponseDTO>>;
    public approvePayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungslaufApproveRequestDTO: ZahlungslaufApproveRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ZahlungslaufResponseDTO>>;
    public approvePayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungslaufApproveRequestDTO: ZahlungslaufApproveRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling approvePayment.');
        }
        if (zahlungslaufId === null || zahlungslaufId === undefined) {
            throw new Error('Required parameter zahlungslaufId was null or undefined when calling approvePayment.');
        }
        if (zahlungBankingToken === null || zahlungBankingToken === undefined) {
            throw new Error('Required parameter zahlungBankingToken was null or undefined when calling approvePayment.');
        }
        if (zahlungslaufApproveRequestDTO === null || zahlungslaufApproveRequestDTO === undefined) {
            throw new Error('Required parameter zahlungslaufApproveRequestDTO was null or undefined when calling approvePayment.');
        }

        let headers = this.defaultHeaders;
        if (zahlungBankingToken !== undefined && zahlungBankingToken !== null) {
            headers = headers.set('zahlung-bankingToken', String(zahlungBankingToken));
        }

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ZahlungslaufResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/zahlungslauf/${encodeURIComponent(String(zahlungslaufId))}/approve`,
            zahlungslaufApproveRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Erstellen eines Zahlungslaufs
     * Erstellt einen Zahlungslauf und weist die Zahlung anschließend an B+S an 
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param zahlungBankingToken Token für den Zahlungsverkehr
     * @param zahlungBankingRedirectUrl URL für die erfolgreiche TAN Eingabe (XS2A)
     * @param zahlungBankingFailureRedirectUrl URL für die nicht erfolgreiche TAN Eingabe (XS2A)
     * @param zahlungBankingClientIp IP des Clients
     * @param zahlungslaufCreateRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignPayment(inhaberId: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, zahlungslaufCreateRequestDTO: ZahlungslaufCreateRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ZahlungslaufResponseDTO>;
    public assignPayment(inhaberId: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, zahlungslaufCreateRequestDTO: ZahlungslaufCreateRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ZahlungslaufResponseDTO>>;
    public assignPayment(inhaberId: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, zahlungslaufCreateRequestDTO: ZahlungslaufCreateRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ZahlungslaufResponseDTO>>;
    public assignPayment(inhaberId: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, zahlungslaufCreateRequestDTO: ZahlungslaufCreateRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling assignPayment.');
        }
        if (zahlungBankingToken === null || zahlungBankingToken === undefined) {
            throw new Error('Required parameter zahlungBankingToken was null or undefined when calling assignPayment.');
        }
        if (zahlungBankingRedirectUrl === null || zahlungBankingRedirectUrl === undefined) {
            throw new Error('Required parameter zahlungBankingRedirectUrl was null or undefined when calling assignPayment.');
        }
        if (zahlungBankingFailureRedirectUrl === null || zahlungBankingFailureRedirectUrl === undefined) {
            throw new Error('Required parameter zahlungBankingFailureRedirectUrl was null or undefined when calling assignPayment.');
        }
        if (zahlungBankingClientIp === null || zahlungBankingClientIp === undefined) {
            throw new Error('Required parameter zahlungBankingClientIp was null or undefined when calling assignPayment.');
        }
        if (zahlungslaufCreateRequestDTO === null || zahlungslaufCreateRequestDTO === undefined) {
            throw new Error('Required parameter zahlungslaufCreateRequestDTO was null or undefined when calling assignPayment.');
        }

        let headers = this.defaultHeaders;
        if (zahlungBankingToken !== undefined && zahlungBankingToken !== null) {
            headers = headers.set('zahlung-bankingToken', String(zahlungBankingToken));
        }
        if (zahlungBankingRedirectUrl !== undefined && zahlungBankingRedirectUrl !== null) {
            headers = headers.set('zahlung-bankingRedirectUrl', String(zahlungBankingRedirectUrl));
        }
        if (zahlungBankingFailureRedirectUrl !== undefined && zahlungBankingFailureRedirectUrl !== null) {
            headers = headers.set('zahlung-bankingFailureRedirectUrl', String(zahlungBankingFailureRedirectUrl));
        }
        if (zahlungBankingClientIp !== undefined && zahlungBankingClientIp !== null) {
            headers = headers.set('zahlung-bankingClientIp', String(zahlungBankingClientIp));
        }

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ZahlungslaufResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/zahlungslauf`,
            zahlungslaufCreateRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lehnt den Zahlungslaufs ab
     * Bricht die Zahlungs bei B+S ab und setzt den Status des Zahlungslaufs auf abgelehnt 
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param zahlungslaufId UUID die einen Zahlungslauf identifziert
     * @param zahlungBankingToken Token für den Zahlungsverkehr
     * @param zahlungBankingClientIp IP des Clients
     * @param zahlungslaufCancelRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelPayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungBankingClientIp: string, zahlungslaufCancelRequestDTO: ZahlungslaufCancelRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ZahlungslaufDTO>;
    public cancelPayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungBankingClientIp: string, zahlungslaufCancelRequestDTO: ZahlungslaufCancelRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ZahlungslaufDTO>>;
    public cancelPayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungBankingClientIp: string, zahlungslaufCancelRequestDTO: ZahlungslaufCancelRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ZahlungslaufDTO>>;
    public cancelPayment(inhaberId: string, zahlungslaufId: string, zahlungBankingToken: string, zahlungBankingClientIp: string, zahlungslaufCancelRequestDTO: ZahlungslaufCancelRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling cancelPayment.');
        }
        if (zahlungslaufId === null || zahlungslaufId === undefined) {
            throw new Error('Required parameter zahlungslaufId was null or undefined when calling cancelPayment.');
        }
        if (zahlungBankingToken === null || zahlungBankingToken === undefined) {
            throw new Error('Required parameter zahlungBankingToken was null or undefined when calling cancelPayment.');
        }
        if (zahlungBankingClientIp === null || zahlungBankingClientIp === undefined) {
            throw new Error('Required parameter zahlungBankingClientIp was null or undefined when calling cancelPayment.');
        }
        if (zahlungslaufCancelRequestDTO === null || zahlungslaufCancelRequestDTO === undefined) {
            throw new Error('Required parameter zahlungslaufCancelRequestDTO was null or undefined when calling cancelPayment.');
        }

        let headers = this.defaultHeaders;
        if (zahlungBankingToken !== undefined && zahlungBankingToken !== null) {
            headers = headers.set('zahlung-bankingToken', String(zahlungBankingToken));
        }
        if (zahlungBankingClientIp !== undefined && zahlungBankingClientIp !== null) {
            headers = headers.set('zahlung-bankingClientIp', String(zahlungBankingClientIp));
        }

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ZahlungslaufDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/zahlungslauf/${encodeURIComponent(String(zahlungslaufId))}/cancel`,
            zahlungslaufCancelRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
