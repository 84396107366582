import {createSelector} from '@ngrx/store';
import {AppState} from '../../interfaces/app-state.interface';
import {identity} from 'rxjs';


export class AppContextSelectors {

  private static select = {
    popupMode: (state: AppState) => state.appContextReducer.popupMode,
    wizardActionButtons: (state: AppState) => state.appContextReducer.wizardActionButtons,
    lastIntentZahlungUpdated: (state: AppState) => state.appContextReducer.lastIntentZahlungUpdated,
    lastIntentZahlungCreated: (state: AppState) => state.appContextReducer.lastIntentZahlungCreated,
  };

  public static popupMode = createSelector(
    this.select.popupMode,
    identity,
  );

  public static wizardActionButtons = createSelector(
    this.select.wizardActionButtons,
    identity,
  );

  public static lastIntentZahlungUpdated = createSelector(
    this.select.lastIntentZahlungUpdated,
    identity,
  );

  public static lastIntentZahlungCreated = createSelector(
    this.select.lastIntentZahlungCreated,
    identity,
  );
}
