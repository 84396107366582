import {map, OperatorFunction} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {MappedHttpErrorResponse} from './interfaces/mapped-http-error-response.interface';


/**
 * Operatorfunktion, die Fehlermeldungen, die vom Server bei API-Anfragen kommen,
 * in ein serialisierbares Objekt umwandelt.
 *
 * Diese Funktion erlaubt es, Fehlerobjekte, die aus HTTP-Anfragen resultieren,
 * so umzuformen, dass sie einfach weitergeleitet und verarbeitet werden können,
 * insbesondere durch Redux Actions. Dies erleichtert das zentrale Management
 * von Fehlerzuständen innerhalb der Anwendung.
 *
 * @param {Function} predicate - Eine Funktion, die das zu transformierende Object übernimmt.
 * @returns {OperatorFunction<T, MappedHttpErrorResponse>} Eine RxJS Operatorfunktion,
 * die von der übergebenen Quelle Daten erhält und ein `MappedHttpErrorResponse` Objekt ausgibt.
 * @template T - Der Typ des Eingangsobjekts, typischerweise `HttpErrorResponse`.
 */
export function mappedHttpErrorResponseOperator<T>(
  predicate: (value: T) => {
    error: any,
  }
): OperatorFunction<T, MappedHttpErrorResponse> {

  return source$ => source$.pipe(
    map(data => {
      const error = data as unknown as HttpErrorResponse;
      const mappedErrorResponse: MappedHttpErrorResponse = JSON.parse(JSON.stringify(error));
      return mappedErrorResponse;
    }),
  );

}
