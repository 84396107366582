import {RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';

export interface ReuseStrategy {
  reuse: string[];
}

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  private storedRoutes = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const reuseableComponents = (route.data.reuseStrategy as ReuseStrategy)?.reuse || [];
    const reuse = reuseableComponents.indexOf(route.routeConfig?.component?.name || '') >= 0;
    return reuse;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const reuseId = (route.routeConfig?.component?.name || '') + route.routeConfig?.outlet;
    this.storedRoutes.set(reuseId, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const reuseId = (route.routeConfig?.component?.name || '') + route.routeConfig?.outlet;
    return !!route.routeConfig && !!this.storedRoutes.get(reuseId);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const reuseId = (route.routeConfig?.component?.name || '') + route.routeConfig?.outlet;
    return this.storedRoutes.get(reuseId) as DetachedRouteHandle;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

}
