/**
 * Belege
 * API für Belege
 *
 * The version of the OpenAPI document: 1.8.0-FEATURE-jf-anforderungen-SNAPSHOT
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasePageableDTO } from './base-pageable-dto';
import { PageableBelegAllOfDTO } from './pageable-beleg-all-of-dto';


/**
 * Gibt das OffSet, Limit und die Sortierung für das Abrufen von Belegen an.<br> 
 */
export interface PageableBelegDTO { 
    /**
     * Wird bei GET-Funktionen genutzt, um anzugeben, wie viele Werte übersprungen werden sollen.
     */
    offset?: number;
    /**
     * Wird bei GET-Funktionen genutzt, um die Anzahl der zurückgegebenen Werte zu begrenzen
     */
    limit?: number;
    sorting?: Array<PageableBelegDTOSortingEnum>;
}
export enum PageableBelegDTOSortingEnum {
    Erstelltasc = 'ERSTELLTASC',
    Erstelltdesc = 'ERSTELLTDESC',
    Geaendertasc = 'GEAENDERTASC',
    Geaendertdesc = 'GEAENDERTDESC',
    Datumasc = 'DATUMASC',
    Datumdesc = 'DATUMDESC',
    Jahrasc = 'JAHRASC',
    Jahrdesc = 'JAHRDESC',
    Nummerasc = 'NUMMERASC',
    Nummerdesc = 'NUMMERDESC',
    Dateigroesseasc = 'DATEIGROESSEASC',
    Dateigroessedesc = 'DATEIGROESSEDESC',
    Betragasc = 'BETRAGASC',
    Betragdesc = 'BETRAGDESC',
    Zahlungsbetragasc = 'ZAHLUNGSBETRAGASC',
    Zahlungsbetragdesc = 'ZAHLUNGSBETRAGDESC',
    Vorgangsartasc = 'VORGANGSARTASC',
    Vorgangsartdesc = 'VORGANGSARTDESC',
    Partnerbezeichnungasc = 'PARTNERBEZEICHNUNGASC',
    Partnerbezeichnungdesc = 'PARTNERBEZEICHNUNGDESC',
    Erlaeuterungasc = 'ERLAEUTERUNGASC',
    Erlaeuterungdesc = 'ERLAEUTERUNGDESC',
    Ibanasc = 'IBANASC',
    Ibandesc = 'IBANDESC',
    Verwendungszweckasc = 'VERWENDUNGSZWECKASC',
    Verwendungszweckdesc = 'VERWENDUNGSZWECKDESC',
    Faelligkeitsdatumasc = 'FAELLIGKEITSDATUMASC',
    Faelligkeitsdatumdesc = 'FAELLIGKEITSDATUMDESC',
    Dauerbelegasc = 'DAUERBELEGASC',
    Dauerbelegdesc = 'DAUERBELEGDESC',
    Uploadbuchstelleasc = 'UPLOADBUCHSTELLEASC',
    Uploadbuchstelledesc = 'UPLOADBUCHSTELLEDESC'
};



