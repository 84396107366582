import {NgModule} from '@angular/core';
import {CircleIdentificatorComponent} from './circle-identificator.component';


@NgModule({
  declarations: [
    CircleIdentificatorComponent
  ],
  exports: [
    CircleIdentificatorComponent
  ]
})
export class CircleIdentificatorModule {
}
