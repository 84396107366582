import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {appContextReducer, initialAppContextState} from './app-context.reducers';
import {initialTransactionState, transactionReducers} from './transaction.reducers';
import {AppState} from '../../interfaces/app-state.interface';
import {environment} from 'src/environments/environment';
import {initialZahlungState, zahlungReducer} from './zahlung.reducers';
import {belegReducers, initialBelegState} from './beleg.reducers';
import {initialJfLibState, jfLibReducers} from '@adnova/jf-ng-components';


export const initialAppState: AppState = {
  ...initialJfLibState,
  appContextReducer: initialAppContextState,
  zahlungReducer: initialZahlungState,
  belegReducer: initialBelegState,
  transactionReducer: initialTransactionState,
};

export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,
  appContextReducer: appContextReducer,
  zahlungReducer: zahlungReducer,
  belegReducer: belegReducers,
  transactionReducer: transactionReducers,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
