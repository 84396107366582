import {Component} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IconService} from './modules/icon/icon.service';
import {IntentActionService} from './services/intent.action.service';
import {PortalMessageBroker} from './portal/portal-message-broker';


@Component({
  selector: 'zlng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private readonly unsubscribe$ = new Subject<void>();

  runningInPortal$: Observable<boolean>;

  title = 'zahlungen';

  constructor(
    private iconService: IconService,   // initialize Icon-Service
    private intentActionService: IntentActionService, // INFO: use for instantiation
    private portalMessageBroker: PortalMessageBroker,
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.runningInPortal$ = this.portalMessageBroker.runningInPortal$
      .pipe(
        takeUntil(this.unsubscribe$),
      );
  }

  doLogout(): void {
    this.oidcSecurityService.logoff();
  }
}
