<div class="info-wrapper">
  <div>
    <div class="title">Zahlung wurde zwischenzeitlich gelöscht</div>
    <div class="message">
      Möchtest du die Überweisung trotzdem ausführen, lege bitte eine neue Überweisung an.
    </div>
    <div class="button-wrapper">
      <button mat-stroked-button
              class="back-btn"
              color="accent"
              (click)="backToOverview()">Zurück zur Zahlungsübersicht
      </button>

      <button mat-flat-button (click)="newPayment()">Neue Überweisung</button>
    </div>
  </div>
</div>
