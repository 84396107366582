/**
 * Zahlung
 * Die API ermöglicht es, Zahlungen zu erstellen, zu aktualisieren, zu bearbeiten und zu löschen. Darüber hinaus können Zahlungen ausgeführt werden. Hierbei fungiert der Zahlung-Service als Schnittstelle zum Banking-Service. Der Banking-Service ist gekapselt für die Kommunikation mit unserem Dienstleister B+S zuständig. <br><br> Um eine Zahlung auszuführen muss zuerst mit `getToken` ein Token für den Zahlungsverkehr geholt werden. Dieses Token wird für die Kommunikation mit B+S benötigt. <br> Anschließend kann mit `getKontaktInfo` die Kontaktinformationen zu einem Login abgerufen werden. Hierbei kann es sein, dass ein Sicherheitsverfahren ausgewählt werden muss. In diesem Fall muss mit `setSicherheitsverfahren` das Sicherheitsverfahren gesetzt werden. <br> Dann kann `assignPayment` aufgerufen werden. In diesem Zuge wird die Zahlung durch B+S bei der Bank bekannt gemacht. Die Antwort von B+S wird dann an den Zahlung-Service zurückgegeben. Gegebenenfalls muss der Anwender eine starke Authentifizierung durchführen. <br> Anschließend kann die Zahlung mit `approvePayment` bestätigt werden. Soll die Zahlung doch nicht durchgeführt werden, kann sie mit `cancelPayment` abgelehnt werden. <br><br> Im Rahmen des Durchführens von Zahlung(en) wird ein Zahlungslauf erstellt, welcher diese protokolliert. <br><br> Die API bietet mit die Möglichkeit Bankkonten anzulegen und deren Kontostände zu aktualisieren. <br><br> Es kann nach nach Banken im SEPA Raum gesucht werden und die in der Vergangenheit verwendeten Banken können abgefragt werden. 
 *
 * The version of the OpenAPI document: 1.1.3
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BankkontoPageableAllOfDTO { 
    sorting?: Array<BankkontoPageableAllOfDTOSortingEnum>;
}
export enum BankkontoPageableAllOfDTOSortingEnum {
    Bezeichnungasc = 'BEZEICHNUNGASC',
    Bezeichnungdesc = 'BEZEICHNUNGDESC',
    Ibanasc = 'IBANASC',
    Ibandesc = 'IBANDESC',
    Bicasc = 'BICASC',
    Bicdesc = 'BICDESC',
    Bankbezeichnungasc = 'BANKBEZEICHNUNGASC',
    Bankbezeichnungdesc = 'BANKBEZEICHNUNGDESC'
};



