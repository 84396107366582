import {createAction, props} from '@ngrx/store';
import {BelegDTO, BelegSuggestionDTO} from '../../openapi/beleg-openapi';
import {Column, Filter, Pagination, Sorting} from '../../modules/beleg-filter-dialog/beleg-filter-dialog.interfaces';


export class BelegActions {

  /*
   * INFO: Beleg-Filter-Dialog Actions
   */

  public static openBelegFilterDialog = createAction(
    '[Beleg Filter Dialog] Open dialog.',
  );

  public static closeBelegFilterDialog = createAction(
    '[Beleg Filter Dialog] Close dialog.',
  );

  public static loadData = createAction(
    '[Beleg Filter Dialog] Load data.',
    props<{
      data?: any[],
      count: number,
    }>(),
  );

  public static setFilter = createAction(
    '[Beleg Filter Dialog] Set filter.',
    props<{
      filter: Filter,
    }>(),
  );

  public static resetFilter = createAction(
    '[Beleg Filter Dialog] Reset filter.',
  );

  public static changePagination = createAction(
    '[Beleg Filter Dialog] Change pagination.',
    props<{
      pagination: Pagination,
    }>(),
  );

  public static resetPagination = createAction(
    '[Beleg Filter Dialog] Reset pagination.',
  );

  public static changeColumns = createAction(
    '[Beleg Filter Dialog] Change columns.',
    props<{
      columns: Column[],
    }>(),
  );

  public static resetColumns = createAction(
    '[Beleg Filter Dialog] Reset columns.',
  );

  public static changeSorting = createAction(
    '[Beleg Filter Dialog] Change sorting.',
    props<{
      sorting: Sorting,
    }>(),
  );

  public static resetSorting = createAction(
    '[Beleg Filter Dialog] Reset sorting.',
  );

  public static belegToAssignSelected = createAction(
    '[Beleg Filter Dialog] A beleg to assign was selected by the user.',
    props<{
      belegDto: BelegDTO,
    }>(),
  );

  /*
   * INFO: Beleg Storniert Actions
   */

  public static openBelegCancelledDialog = createAction(
    '[Overview Table] Opens the beleg cancelled dialog.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static closeBelegCancelledDialog = createAction(
    '[Overview Table] Closes the beleg cancelled dialog.',
  );

  /*
   * INFO: Beleg Vorschlagswerte Laden Actions
   */

  public static getBelegSuggestions = createAction(
    '[Edit Form] Loads the suggestions.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  /*
   * INFO: Beleg Preview Actions
   */

  public static loadContentById = createAction(
    '[Preview] Load content by ID.',
    props<{
      inhaberId: string,
      belegId: string,
      closeable?: boolean,
    }>(),
  );

  public static loadContent = createAction(
    '[Preview] Load content.',
    props<{
      belegDto: BelegDTO,
      closeable?: boolean,
    }>(),
  );

  public static loadIframeContent = createAction(
    '[Preview] Load iFrame content.',
    props<{
      belegDto: BelegDTO,
    }>(),
  );

  public static contentLoaded = createAction(
    '[Preview] Content loaded.',
    props<{
      belegDto?: BelegDTO,
      content: string,
    }>(),
  );

  public static iFrameContentLoaded = createAction(
    '[Preview] iFrame Dialog preview Content loaded.',
    props<{
      belegDto?: BelegDTO,
      content: string,
    }>(),
  );

  public static closeBelegPreview = createAction(
    '[Preview] Close beleg preview.',
  );

  public static resetBelegPreview = createAction(
    '[Preview] Reset preview.',
  );

  public static highlightWords = createAction(
    '[Preview] Hightlight words.',
    props<{
      words: string[],
    }>(),
  );
}
