import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'zlng-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
})
export class HeaderActionsComponent {

  @Input()
  inTransactionWizard = false;

  @Output()
  assignNewBelegEvent = new EventEmitter();

  @Output()
  removeBelegAssociationEvent = new EventEmitter();

  constructor() {
  }

  public assignNewBeleg(): void {
    this.assignNewBelegEvent.emit();
  }

  public removeBelegAssociation(): void {
    this.removeBelegAssociationEvent.emit();
  }
}
