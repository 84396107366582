import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';


@Component({
  selector: 'zlng-beleglos',
  templateUrl: './beleglos.component.html',
  styleUrls: ['./beleglos.component.scss'],
})
export class BeleglosComponent implements OnDestroy {

  @Output()
  openBelegChooser = new EventEmitter<void>();

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
  ) {
  }

  doOpenBelegChooser(): void {
    this.openBelegChooser.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
