<div id="info-wrapper" *ngIf="!showDeleteWrapper">
  <div>
    <div class="title">Zahlung löschen</div>
    <div class="message">
      Soll die Zahlung wirklich gelöscht werden?<br>
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </div>
    <div class="button-wrapper">
      <button mat-flat-button
              class="delete-btn"
              color="accent"
              (click)="deleteZahlung()">Ja, Zahlung löschen
      </button>
      <button mat-flat-button (click)="closeDialog()">Abbrechen</button>
    </div>
  </div>
</div>

<div id="error-wrapper" *ngIf="showDeleteWrapper">
  <div>
    <div class="title">Zahlung löschen</div>
    <div class="message" [innerHTML]="'Diese Zahlung konnte nicht gelöscht werden' + paymentDeleteFailureInfo"></div>
    <div class="link">
      Problem besteht weiterhin?<br>
      <a href="mailto:service@just-farming.de" class="email">Just-Farming Benutzerservice kontaktieren.</a>
    </div>
    <div class="button-wrapper">
      <button mat-flat-button (click)="closeDialog()" color="accent">Schließen</button>
    </div>
  </div>
</div>
