import {Component, Input} from '@angular/core';

@Component({
  selector: 'zlng-circle-identificator',
  templateUrl: './circle-identificator.component.html',
  styleUrls: ['./circle-identificator.component.scss']
})
export class CircleIdentificatorComponent {

  @Input()
  description = '';

  @Input()
  color = '';

  constructor() {
  }

  calcDescription(): string {
    if (this.description) {
      return this.description.replace(/[^\wöäüßÖÄÜ]/g, '').slice(0, 2).toUpperCase();
    }

    return '';
  }
}
