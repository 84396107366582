import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AppState} from '../../interfaces/app-state.interface';
import {ZahlungActions} from '../../store/actions/zahlung.actions';
import {BelegActions} from '../../store/actions/beleg.actions';
import {BelegSelectors} from '../../store/selectors/beleg.selectors';


@Component({
  selector: 'zlng-delete-dialog',
  templateUrl: './beleg-cancelled-dialog.component.html',
  styleUrls: ['./beleg-cancelled-dialog.component.scss']
})
export class BelegCancelledDialogComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private inhaberId = '';

  private zahlungId = '';

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.store.pipe(
      select(BelegSelectors.belegCancelledDialogInhaberId),
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;

      this.inhaberId = inhaberId;
    });

    this.store.pipe(
      select(BelegSelectors.belegCancelledDialogZahlungId),
      takeUntil(this.unsubscribe$),
    ).subscribe(zahlungId => {
      if (!zahlungId) return;

      this.zahlungId = zahlungId;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openEdit(): void {
    this.store.dispatch(ZahlungActions.resetActualZahlung());
    this.router.navigate(['/edit/inhaber/' + this.inhaberId + '/zahlung/' + this.zahlungId]);
    this.store.dispatch(BelegActions.closeBelegCancelledDialog());
    this.store.dispatch(BelegActions.openBelegFilterDialog());
  }

  deleteZahlung(): void {
    this.store.dispatch(ZahlungActions.openZahlungDeleteDialog({
      inhaberId: this.inhaberId,
      zahlungId: this.zahlungId,
    }));

    this.store.dispatch(BelegActions.closeBelegCancelledDialog());
  }

  closeDialog(): void {
    this.store.dispatch(BelegActions.closeBelegCancelledDialog());
  }
}
