import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatLegacyPaginatorIntl as MatPaginatorIntl, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {Store} from '@ngrx/store';
import {ZahlungDTO} from '../../openapi/zahlung-openapi';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {getGermanPaginatorIntl} from '../german-paginator-intl';
import {AppState} from '../../interfaces/app-state.interface';
import {TransactionSelectors} from '../../store/selectors/transaction.selectors';
import {TransactionActions} from '../../store/actions/transaction.actions';


@Component({
  selector: 'zlng-payment-browser',
  templateUrl: './payment-browser.component.html',
  styleUrls: ['./payment-browser.component.scss'],
  providers: [{provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()}],
})
export class PaymentBrowserComponent implements OnInit, OnDestroy {

  public finalPayments: ZahlungDTO[] = [];

  public currentIndex?: number;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {

    this.store.select(TransactionSelectors.collectivePreviewIndex).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(collectivePreviewIndex => {
      if (this.currentIndex !== collectivePreviewIndex) {
        this.currentIndex = collectivePreviewIndex;
      }
    });

    this.store.select(TransactionSelectors.finalPayments).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(finalPayments => {
      this.finalPayments = finalPayments;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  browse($event: PageEvent): void {
    const index = $event.pageIndex;
    const zahlungDto = this.finalPayments[index];
    this.store.dispatch(TransactionActions.collectivePreviewBrowse({
      page: index,
    }));
    this.store.dispatch(TransactionActions.selectPreview({
      zahlungDto,
    }));
  }
}
