<div class="wrapper d-flex flex-row"
     (mouseover)="showArrow=true"
     (mouseout)="showArrow=false">
  <div class="circle-identificator-wrapper d-flex flex-row" *ngIf="blz">
    <zlng-circle-identificator
      [description]="bank"
      [color]="identificatorColor"
    ></zlng-circle-identificator>
  </div>

  <div class="account-wrapper d-flex flex-column">
    <div class="description">IBAN: {{iban}}</div>

    <div class="bank">{{bank}}</div>
  </div>

  <div class="arrow-wrapper d-flex flex-row">

    <zlng-icon icon="common:arrow_right"
               primaryStroke="black"
               *ngIf="showArrow"
    ></zlng-icon>
  </div>
</div>
