import {Component} from '@angular/core';

@Component({
  selector: 'zlng-beleglos-locked',
  templateUrl: './beleglos-locked.component.html',
  styleUrls: ['./beleglos-locked.component.scss'],
})
export class BeleglosLockedComponent {

  constructor() {
  }

}
