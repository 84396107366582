import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BankService, ZahlungDTO, ZahlungPageableDTOSortingEnum, ZahlungService} from '../../openapi/zahlung-openapi';
import {NGXLogger} from 'ngx-logger';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {catchError, concatMap, map, switchMap, tap} from 'rxjs/operators';
import {mergeMap, of} from 'rxjs';
import {ZahlungActions} from '../actions/zahlung.actions';
import {BankInfoLoadedData} from '../../interfaces/bank-info-loaded-data.interface';
import {EditZahlungDataV1, IntentActionService, OverviewOpenDataV1} from '../../services/intent.action.service';
import {DeleteDialogComponent} from '../../modules/delete-dialog/delete-dialog.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {WidgetHeaderData} from '../../modules/widget/widget-header/widget-header-data';
import {WidgetInhaberData} from '../../modules/widget/widget-header/widget-inhaber-data';
import {DeletedDialogComponent} from '../../modules/deleted-dialog/deleted-dialog.component';
import {TypedAction} from '@ngrx/store/src/models';
import {mappedHttpErrorResponseOperator} from '../../mapped-http-error-response.operator';
import {SentryActions} from '../actions/sentry.actions';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';


@Injectable()
export class ZahlungEffects {

  private zahlungDeleteDialogMatDialogRef?: MatDialogRef<DeleteDialogComponent>;
  private zahlungDeletedDialogMatDialogRef?: MatDialogRef<DeletedDialogComponent>;

  constructor(
    private actions$: Actions,
    private zahlungService: ZahlungService,
    private bankService: BankService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private intentActionService: IntentActionService,
    private dialog: MatDialog,
  ) {
  }

  /*
   * INFO: Zahlung Erstellen Effects
   */

  readonly createZahlung$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.createZahlung),
        concatMap(action => this.zahlungService.createZahlung(
          action.inhaberId,
          action.request,
        ).pipe(
          catchError(error => {
            this.logger.error('Could not create zahlung', error);

            let errorMsg = 'Fehler beim Anlegen der Zahlung. Bitte probiere es später erneut.';
            switch (error.status) {
              case 400: {
                const message = error.error.message;
                if (message) {
                  errorMsg = message;
                }
                break;
              }
              case 404: {
                errorMsg = 'Inhaber kann nicht gefunden werden. Bitte probiere es später erneut.';
                break;
              }
            }

            this.snackbar.open(errorMsg, undefined, {duration: 5000, panelClass: 'error'});

            return error;
          }),
        )),
        map(zahlungDto => zahlungDto as ZahlungDTO),
        tap(zahlungDto => {
          this.snackbar.open('Zahlung erfolgreich erstellt', undefined, {duration: 5000});
          this.logger.debug('Created zahlungDto', zahlungDto);
          if (this.intentActionService.isRunningInPortal()) {
            this.intentActionService.doZahlungCreatedV1({
              inhaberId: zahlungDto.inhaberId,
              zahlungenIds: [zahlungDto.id],
            });
          }
        }),
        map(zahlungDto => zahlungDto as ZahlungDTO),
        map(zahlungDto => ZahlungActions.createZahlungSuccess({
          zahlungDto: zahlungDto,
        })),
      )
  );


  /*
   * INFO: Zahlung Laden Effects
   */

  readonly getZahlung$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.getZahlung),
        concatMap(action => this.zahlungService.getZahlung(
            action.inhaberId,
            action.zahlungId,
          ).pipe(
            catchError(error => {
              this.logger.error('Could not read single zahlung', error);

              let errorMsg = '';
              switch (error.status) {
                case 404: {
                  errorMsg = 'Inhaber kann nicht gefunden werden';
                  break;
                }
                default: {
                  errorMsg = 'Fehler beim Laden der Zahlung';
                }
              }

              this.snackbar.open(errorMsg, undefined, {duration: 5000, panelClass: 'error'});

              return of(error);
            }),
          )
        ),
        tap(zahlungDto => this.logger.debug('Loaded zahlungDto', zahlungDto)),
        map(zahlungDto => zahlungDto as ZahlungDTO),
        map(zahlungDto => ZahlungActions.getZahlungSuccess({
          zahlungDto: zahlungDto,
        })),
      )
  );

  readonly readZahlungen$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.readZahlungen),
        concatMap(({
                     inhaberId,
                     filterDto,
                     pageableDto,
                   }) => {
          return this.zahlungService.readZahlungen(
            inhaberId,
            filterDto,
            pageableDto,
          ).pipe(
            switchMap(zahlungDtos => {
              this.logger.debug(
                'read zahlungen succeed. zahlungIds:',
                zahlungDtos.map(zahlung => zahlung.id),
              );

              // INFO: Wenn mehrere Zahlungen mit demselben Beleg verknüpft sind, soll dieser nicht mehrfach geladen werden
              const zahlungWithBeleg = zahlungDtos.filter(zahlung => zahlung.belegId !== undefined);
              const distinctBelegIds = [...new Set(zahlungWithBeleg.map((item) => item.belegId!))];

              return [
                ZahlungActions.countBadgeValue({
                  inhaberId,
                }),
                ZahlungActions.readZahlungenSuccess({
                  zahlungDtos,
                }),
              ];
            }),
            catchError(error => of(error).pipe(
              map(() => {
                this.logger.error(
                  'read zahlungen failed. error:',
                  error,
                );

                return ZahlungActions.readZahlungenFailure({
                  status: error.status,
                });
              })
            ))
          );
        }),
      ),
  );

  readonly readZahlungenFailure$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.readZahlungenFailure),
        map(({
               status,
             }) => {

          let errorMsg = '';
          switch (status) {
            case 404: {
              errorMsg = 'Inhaber kann nicht gefunden werden';
              break;
            }
            default: {
              errorMsg = 'Fehler beim Laden der Zahlungen';
            }
          }

          this.snackbar.open(
            errorMsg,
            undefined,
            {
              duration: 5000,
              panelClass: 'error',
            });
        }),
      ), {dispatch: false}
  );

  readonly reloadZahlungDtos$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.reloadZahlungDtos),
        map(() => ZahlungActions.reloadZahlungDtosTriggered()),
      )
  );

  /*
   * INFO: Zahlung Anzahl Laden Effects
   */

  readonly countZahlungen$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.countZahlungen),
        concatMap(action => this.zahlungService.countZahlungen(
          action.inhaberId,
          action.filterDto,
        ).pipe(
          map(response => {
            const counted = response.count;

            this.logger.debug(
              'count zahlungen succeeded. counted:',
              counted,
            );

            return ZahlungActions.updateZahlungCounter({counted});
          }),
          catchError(error => of(error).pipe(
            map(() => {
              this.logger.debug(
                'count zahlungen failed. error:',
                error,
              );

              return ZahlungActions.countZahlungenFailure({
                status: error.status,
              });
            }),
          )),
        )),
      )
  );

  readonly countZahlungenFailure$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.countZahlungenFailure),
        map(({
               status,
             }) => {

          let errorMsg = '';
          switch (status) {
            case 404: {
              errorMsg = 'Inhaber kann nicht gefunden werden';
              break;
            }
            default: {
              errorMsg = 'Fehler beim Laden der Anzahl der Zahlungen';
            }
          }

          this.snackbar.open(
            errorMsg,
            undefined,
            {
              duration: 5000,
              panelClass: 'error',
            });
        }),
      ), {dispatch: false}
  );

  /*
   * INFO: Zahlung Löschen Effects
   */

  readonly openDeleteDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(ZahlungActions.openZahlungDeleteDialog),
      tap(() => {
        this.zahlungDeleteDialogMatDialogRef = this.dialog.open(DeleteDialogComponent, {
          disableClose: true,
          autoFocus: false,
        });
      }),
    ), {dispatch: false}
  );

  readonly openDeletedDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(ZahlungActions.openZahlungDeletedDialog),
      tap(action => {
        this.zahlungDeletedDialogMatDialogRef?.close();
        this.zahlungDeletedDialogMatDialogRef = this.dialog.open(DeletedDialogComponent, {
          disableClose: true,
          autoFocus: false,
          data: {...action},
        });
      }),
    ), {dispatch: false}
  );

  readonly closeDeleteDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(ZahlungActions.closeZahlungDeleteDialog),
      tap(() => {
        this.zahlungDeleteDialogMatDialogRef?.close();
      }),
    ), {dispatch: false}
  );

  readonly closeDeletedDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(ZahlungActions.closeZahlungDeletedDialog),
      tap(() => {
        this.zahlungDeletedDialogMatDialogRef?.close();
      }),
    ), {dispatch: false}
  );

  /*
   * INFO: Zahlung Aktualisieren Effects
   */

  readonly updateZahlung$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.updateZahlung),
        concatMap(action => this.zahlungService.updateZahlung(
          action.inhaberId,
          action.zahlungId,
          action.requestDto,
        ).pipe(
          tap(() => {
            if (this.intentActionService.isRunningInPortal()) {
              this.intentActionService.doZahlungUpdatedV1({
                inhaberId: action.inhaberId,
                zahlungenIds: [action.zahlungId],
              });
            }
          }),
          catchError(error => {
            this.logger.error('Could not update zahlung', error);

            let errorMsg = '';
            switch (error.status) {
              case 404: {
                errorMsg = 'Inhaber kann nicht gefunden werden';
                break;
              }
              default: {
                errorMsg = 'Fehler beim Aktualisieren der Zahlung';
              }
            }

            this.snackbar.open(errorMsg, undefined, {duration: 5000, panelClass: 'error'});

            return error;
          }),
        )),
        tap(zahlungDto => {
          this.snackbar.open('Zahlung erfolgreich aktualisiert', undefined, {duration: 5000});
          this.logger.debug('Updated zahlungDto', zahlungDto);
        }),
        map(() => ZahlungActions.updateZahlungSuccess()),
      )
  );

  /*
   * INFO: Zahlung Löschen Effects
   */

  readonly zahlungtDelete$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.zahlungDelete),
        switchMap(action => {
          return this.zahlungService.deleteZahlung(action.inhaberId, action.zahlungId)
            .pipe(
              switchMap(() => {
                this.logger.debug('delete zahlung successful', action.zahlungId);

                if (this.intentActionService.isRunningInPortal()) {
                  this.intentActionService.doPaymentDeletedV1({
                    inhaberId: action.inhaberId,
                    zahlungenIds: [action.zahlungId],
                  });
                }

                return [
                  ZahlungActions.countBadgeValue({
                    inhaberId: action.inhaberId,
                  }),
                  ZahlungActions.reloadZahlungDtos(),
                  ZahlungActions.zahlungDeleteResponse({
                    success: true,
                    zahlungDeleteFailureInfo: '',
                  }),
                ];
              }),
              catchError(error => {
                this.logger.debug('delete zahlung error', error);

                let paymentDeleteFailureInfo = '';

                switch (error.status) {
                  case 403:
                    paymentDeleteFailureInfo = ',<br>denn sie wurde nicht in Just Farming erstellt. <br> <br> ' +
                      'Weiteres Vorgehen: Lösche diese Zahlung direkt in ADNOVA+.<br> ' +
                      'Im Folgenden wird sie automatisch aus Just Farming entfernt.';
                    break;
                  case 404:
                    paymentDeleteFailureInfo = ',<br>denn der Inhaber kann nicht gefunden werden.';
                    break;
                  case 423:
                    paymentDeleteFailureInfo = ',<br>denn der Datensatz ist gesperrt.';
                    break;
                }

                return of(ZahlungActions.zahlungDeleteResponse({
                  success: false,
                  zahlungDeleteFailureInfo: paymentDeleteFailureInfo,
                }));
              }),
            );
        }),
      )
  );

  /*
   * INFO: Zahlung Edit Form Effects
   */

  readonly readBank$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.readBank),
        concatMap(action => {
            return this.bankService.readBank(
              action.iban
            ).pipe(
              map(bankInfo => {
                return {
                  iban: action.iban,
                  bic: bankInfo.bic,
                  bankbezeichnung: bankInfo.bezeichnung
                } as BankInfoLoadedData;
              }),
              catchError(error => {
                this.logger.debug('could not read bank info', error);

                return of({
                  iban: action.iban,
                  bic: '',
                  bankbezeichnung: ''
                } as BankInfoLoadedData);
              }),
            );
          }
        ),
        tap(data => {
          this.logger.debug('read bank info', data);
        }),
        map(data => data as BankInfoLoadedData),
        map(data => {
          return ZahlungActions.bankInfoLoaded({
            iban: data.iban,
            bic: data.bic,
            bank: data.bankbezeichnung,
          });
        }),
      )
  );

  /**
   * Lädt die Bank-Infos zu den Iban-Vorschlägen.
   */
  readonly loadBankinfoFromIban$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.loadBankinfoFromIban),
        concatMap(({iban}) => {
            return this.bankService.readBank(iban).pipe(
              concatMap(bankInfo => {
                this.logger.debug('read bank info from iban suggestion succeeded', bankInfo);

                return [
                  ZahlungActions.loadBankinfoFromSuggestionSuccess({
                    iban,
                    bic: bankInfo.bic,
                    bank: bankInfo.bezeichnung,
                    blz: bankInfo.blz,
                  }),
                ];
              }),
              catchError(error => of(error).pipe(
                mappedHttpErrorResponseOperator(error),
                map(error => {
                  // INFO: Dem Anwender hier bewusst keine Fehlermeldung anzeigen. Es reicht den Fehler zu loggen.
                  const errorTitle = 'could not read bank info from iban suggestion';
                  return ZahlungActions.logError({errorTitle, error});
                }),
              )),
            );
          }
        ),
      )
  );

  readonly readBankSuggestions$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.readBankSuggestions),
        concatMap(({
                     inhaberId,
                     iban,
                   }) => {
            return this.zahlungService.readBankSuggestions(inhaberId, iban).pipe(
              concatMap(bankSuggestionDtos => {
                this.logger.debug('read bank suggestions succeeded.', bankSuggestionDtos);

                let actions: TypedAction<any>[] = [];

                for (const bankSuggestionDto of bankSuggestionDtos) {
                  actions.push(ZahlungActions.loadBankinfoFromSuggestionSuccess({
                    iban: bankSuggestionDto.iban!,
                    bic: bankSuggestionDto.bic,
                    blz: bankSuggestionDto.blz,
                    bank: bankSuggestionDto.bezeichnung,
                  }));
                }

                return actions;
              }),
              catchError(error => of(error).pipe(
                mappedHttpErrorResponseOperator(error),
                map(error => {
                  // INFO: Dem Anwender hier bewusst keine Fehlermeldung anzeigen. Es reicht den Fehler zu loggen.
                  const errorTitle = 'could not read bank suggestions';
                  return ZahlungActions.logError({errorTitle, error});
                }),
              )),
            );
          }
        ),
      )
  );

  readonly logError$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.logError),
        tap(({errorTitle, error}) => {
          this.logger.error(errorTitle, error);
        }),
        map(({errorTitle, error}) => {
          let sentryError: MappedHttpErrorResponse = {};
          if (typeof error !== 'string') {
            sentryError = error;
          }

          return SentryActions.captureException({
            error: sentryError,
            extras: {}
          });
        }),
      ),
  );

  /*
  * INFO: Zahlung Utility Effects
  */

  readonly countBadgeValue$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.countBadgeValue),
        concatMap(action => this.zahlungService.countZahlungen(
          action.inhaberId,
          {
            offen: true,
            angewiesen: false,
          }).pipe(
          map(response => {
            const counted = response.count;

            this.logger.debug(
              'count zahlungen for badge value succeeded. counted:',
              counted,
            );

            return ZahlungActions.addBadgeValue({
              inhaberId: action.inhaberId,
              badgeValue: response.count,
            });
          }),
          catchError(error => of(error).pipe(
            map(() => {
              this.logger.debug(
                'count zahlungen for badge value failed. error:',
                error,
              );

              return ZahlungActions.countZahlungenFailure({
                status: error.status,
              });
            })
          )),
        )),
      )
  );

  /*
   * INFO: Utility-Widget Effects
   */

  /**
   * Erstellt ein WidgetHeaderData für das Utility-Widget.
   */
  readonly createWidgetHeaderData$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.createWidgetHeaderData),
        map(action => {
          const widgetHeaderData: WidgetHeaderData = {
            title: 'Zahlungen',
          };

          for (const inhaber of action.inhabersDtos) {
            const inhaberData: WidgetInhaberData = {
              inhaber,
              todoExists: false,
            };

            if (widgetHeaderData.widgetInhaberDatas) {
              widgetHeaderData.widgetInhaberDatas = [
                ...widgetHeaderData.widgetInhaberDatas!,
                inhaberData,
              ];
            } else {
              widgetHeaderData.widgetInhaberDatas = [inhaberData];
            }
          }

          return ZahlungActions.widgetHeaderDataCreated({widgetHeaderData});
        }),
      )
  );

  /**
   * Lädt Zahlungen für einen Inhaber.
   */
  readonly readZahlungenUtilityWidget$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.readZahlungenUtilityWidget),
        mergeMap(({
                    inhaberDto,
                    pageIndex,
                    pageSize,
                    maturityTo,
                    maturityFrom,
                  }) => {

          return this.zahlungService.readZahlungen(
            inhaberDto.id,
            {
              offen: true,
              angewiesen: false,
              faelligkeitsdatumVon: maturityFrom,
              faelligkeitsdatumBis: maturityTo,
            },
            {
              offset: pageIndex * pageSize,
              limit: pageSize,
              sorting: [ZahlungPageableDTOSortingEnum.Faelligkeitsdatumasc],
            }
          ).pipe(
            switchMap(zahlungDtos => {
              return [
                ZahlungActions.readZahlungenUtilityWidgetSuccess({
                  inhaberId: inhaberDto.id,
                  zahlungDtos,
                  pageIndex,
                  pageSize,
                  maturity: maturityTo,
                }),
                ZahlungActions.widgetHeaderDataUpdated({
                  inhaberId: inhaberDto.id,
                  zahlungDtos,
                }),
              ];
            }),
            catchError(error => of(error).pipe(
              map(() => {
                  this.logger.error(
                    'read payments for utility-widget failed. error:',
                    error,
                  );

                  return ZahlungActions.readZahlungenFailure({
                    status: error.status,
                  });
                }
              ))),
          );
        }),
      )
  );

  /**
   * Lädt die Anzahl der Zahlungen für einen Inhaber.
   */
  readonly countZahlungenUtilityWidget$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.countUtilityWidgetZahlungen),
        concatMap(action => {
          return this.zahlungService.countZahlungen(
            action.inhaberDto.id,
            {
              offen: true,
              angewiesen: false,
              faelligkeitsdatumVon: action.maturityFrom,
              faelligkeitsdatumBis: action.maturityTo,
            }).pipe(
            map(responseDto => {

              this.logger.debug(
                'count zahlungen for utility-widget value succeeded. counted:',
                responseDto.count,
              );

              return ZahlungActions.zahlungCountSuccess({
                inhaberId: action.inhaberDto.id,
                responseDto: responseDto,
                maturity: action.maturityTo,
              });
            }),
            catchError(error => of(error).pipe(
              map(() => {
                this.logger.debug(
                  'count zahlungen for utility-widget failed. error:',
                  error,
                );

                return ZahlungActions.countZahlungenFailure({
                  status: error.status,
                });
              })
            )),
          );
        }),
      )
  );

  /**
   * Triggert den Intent zum Öffnen der Übersicht-Seite
   */
  readonly openOverview$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.openOverviewPage),
        map(action => {
          const data: OverviewOpenDataV1 = {
            inhaberId: action.inhaberId,
          };

          if (this.intentActionService.isRunningInPortal()) {
            this.intentActionService.doOverviewOpenV1(data);
          } else {
            this.intentActionService.handleOverviewOpenV1(data);
          }
        })
      ), {dispatch: false}
  );

  /**
   * Triggert den Intent zum Öffnen der Bearbeiten-Seite
   */
  readonly openEdit$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ZahlungActions.openEditPage),
        map(action => {
          const data: EditZahlungDataV1 = {
            inhaberId: action.inhaberId,
            zahlungId: action.zahlungId,
          };

          if (this.intentActionService.isRunningInPortal()) {
            this.intentActionService.doEditOpenV1(data);
          } else {
            this.intentActionService.handleEditZahlungV1(data);
          }
        })
      ), {dispatch: false}
  );

}
