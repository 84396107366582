import {createAction, props} from '@ngrx/store';
import {
  EmpfaengerDTO,
  InhaberDTO,
  ZahlungCountResponseDTO,
  ZahlungCreateRequestDTO,
  ZahlungDTO,
  ZahlungFilterDTO,
  ZahlungPageableDTO,
  ZahlungPageableDTOSortingEnum,
  ZahlungUpdateRequestDTO,
} from '../../openapi/zahlung-openapi';
import {BelegDTO, BelegSuggestionDTO} from '../../openapi/beleg-openapi';
import {WidgetHeaderData} from '../../modules/widget/widget-header/widget-header-data';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';


export class ZahlungActions {

  /*
   * INFO: Zahlung Erstellen Actions
   */

  public static createZahlung = createAction(
    '[Edit Form] Create zahlung.',
    props<{
      inhaberId: string,
      request: ZahlungCreateRequestDTO,
      startTransaction: boolean,
    }>(),
  );

  public static createZahlungSuccess = createAction(
    '[Edit Form] Create zahlung succeeded.',
    props<{
      zahlungDto: ZahlungDTO,
    }>(),
  );

  public static readZahlungenFailure = createAction(
    '[Benutzer Effect] Read zahlungen failed.',
    props<{
      status: number,
    }>()
  );

  /*
   * INFO: Zahlung Aktualisieren Actions
   */

  public static updateZahlung = createAction(
    '[Edit Form] Update zahlung.',
    props<{
      inhaberId: string,
      zahlungId: string,
      requestDto: ZahlungUpdateRequestDTO,
      startTransaction: boolean,
    }>(),
  );

  public static updateZahlungSuccess = createAction(
    '[Edit Form] Update zahlung succeeded.',
  );

  public static updateZahlungNotNeeded = createAction(
    '[Edit Form] Update payment not needed.',
    props<{
      startTransaction: boolean,
    }>(),
  );

  /*
   * INFO: Zahlung Laden Actions
   */

  public static getZahlung = createAction(
    '[Edit Form] Get zahlung.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static getZahlungSuccess = createAction(
    '[Edit Form] Get zahlung succeeded.',
    props<{
      zahlungDto: ZahlungDTO,
    }>(),
  );

  public static readZahlungen = createAction(
    '[Overview] Read zahlungen.',
    props<{
      inhaberId: string,
      filterDto: ZahlungFilterDTO,
      pageableDto: ZahlungPageableDTO,
      pageIndex: number,
    }>(),
  );

  public static readZahlungenSuccess = createAction(
    '[Zahlung Effect] Read zahlungen succeeded.',
    props<{
      zahlungDtos: ZahlungDTO[],
    }>(),
  );

  public static searchValueChanged = createAction(
    '[Overview] Search value changes.',
    props<{
      searchValue: string,
    }>(),
  );

  public static updateFilterStatusOpen = createAction(
    '[Left Panel] Updates the filterStatusOpen.',
    props<{
      filterStatusOpen: boolean,
    }>(),
  );

  public static reloadZahlungDtos = createAction(
    '[Overview Table] Trigger a reload of all zahlungen.',
  );

  public static reloadZahlungDtosTriggered = createAction(
    '[Overview Table] Triggered a reload of all zahlungen.',
  );

  /*
   * INFO: Zahlung Anzahl Laden Actions
   */

  public static countZahlungen = createAction(
    '[Overview] Count zahlungen.',
    props<{
      inhaberId: string,
      filterDto: ZahlungFilterDTO,
    }>(),
  );

  public static updateZahlungCounter = createAction(
    '[Overview Paginator] Updates of zahlung counter.',
    props<{
      counted: number,
    }>(),
  );

  public static countZahlungenFailure = createAction(
    '[Benutzer Effect] Count zahlungen failed.',
    props<{
      status: number,
    }>()
  );

  /*
   * INFO: Zahlung Löschen Actions
   */

  public static openZahlungDeleteDialog = createAction(
    '[Overview Table] Opens the delete dialog.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static openZahlungDeletedDialog = createAction(
    '[Intent Action Service] Informs that the payment was deleted meanwhile.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static closeZahlungDeletedDialog = createAction(
    '[Intent Action Service] Close the information that the payment was deleted meanwhile. ',
  );

  public static openZahlungDeleteEmbedded = createAction(
    '[Intent Action Service] Opens the embedded delete component.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static closeZahlungDeleteDialog = createAction(
    '[Overview Table] Closes the delete dialog.',
  );

  public static updateZahlungDeleteDialogData = createAction(
    '[Overview Table] Updates the delete dialog data',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static zahlungDelete = createAction(
    '[Overview Table or Belege-Online Intent] Delete of payment.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  // TODO: Diesen Effect in 2 Effects splitten
  public static zahlungDeleteResponse = createAction(
    '[Delete Dialog Effect] Delete payment success.',
    props<{
      success: boolean,
      zahlungDeleteFailureInfo: string,
    }>(),
  );

  /*
   * INFO: Zahlung Table Actions
   */

  public static sortingChanged = createAction(
    '[Overview] Sort value changes.',
    props<{
      sortingEnum: ZahlungPageableDTOSortingEnum,
    }>(),
  );

  public static pageIndexChanged = createAction(
    '[Overview] Page index changes.',
    props<{
      pageIndex: number,
    }>(),
  );

  public static addCheckedZahlungDto = createAction(
    '[Overview Table] Adds a zahlung to the checked payment list.',
    props<{
      checkedZahlungDto: ZahlungDTO,
    }>(),
  );

  public static removeCheckedZahlungDto = createAction(
    '[Overview Table] Removes a zahlung from the checked payment list.',
    props<{
      checkedZahlungDto: ZahlungDTO,
    }>(),
  );

  public static resetCheckedZahlungDtos = createAction(
    '[Overview Table] Resets the checked zahlungen.',
  );

  /*
   * INFO: Zahlung Edit Form Actions
   */

  public static resetActualZahlung = createAction(
    '[Edit Form] Reset the show zahlung on edit page.',
  );

  public static setActualZahlungNull = createAction(
    '[Edit Form] Set zahlung to null.',
  );

  public static setToActualZahlung = createAction(
    '[Edit Form] Set to actual zahlung.',
  );

  public static assignBelegToZahlung = createAction(
    '[Edit Form] Assign beleg to zahlung.',
    props<{
      belegDto: BelegDTO,
    }>(),
  );

  public static initialAssignedBelegLoaded = createAction(
    '[Edit Form] Loaded the initial assigned beleg from zahlung.',
    props<{
      belegDto: BelegDTO,
    }>(),
  );

  public static readBank = createAction(
    '[Edit Form] Load bankInfo.',
    props<{
      iban: string,
    }>()
  );

  public static bankInfoLoaded = createAction(
    '[Edit Form] BankInfo loaded.',
    props<{
      iban: string,
      bank: string,
      bic: string,
    }>()
  );

  public static changedFormValues = createAction(
    '[Edit Form] Form values changed.',
    props<{
      empfaengerDto?: EmpfaengerDTO,
      iban?: string,
      betrag?: number,
      verwendungszweck?: string,
      faelligkeitsdatum?: string,
    }>()
  );

  public static changedFormIban = createAction(
    '[Edit Form] Iban changed.',
    props<{
      iban?: string,
    }>()
  );

  public static changedFormEmpfaenger = createAction(
    '[Edit Form] Empfaenger changed.',
    props<{
      empfaengerDto?: EmpfaengerDTO,
    }>()
  );

  public static changedFormBetrag = createAction(
    '[Edit Form] Betrag changed.',
    props<{
      betrag?: number,
    }>()
  );

  public static changedFormVerwendungszweck = createAction(
    '[Edit Form] Verwendungszweck changed.',
    props<{
      verwendungszweck?: string,
    }>()
  );

  public static changedFormFaelligkeitsdatum = createAction(
    '[Edit Form] Due date changed.',
    props<{
      faelligkeitsdatum?: string,
    }>()
  );

  public static resetEditPageOnInit = createAction(
    '[Edit Form] Resets initial marks.',
  );

  public static activatePopupMode = createAction(
    '[Edit Form] Activates the popup.',
  );

  public static removeBelegAssociation = createAction(
    '[Edit Form] Removes the beleg association.',
  );

  public static updateZahlungAngewiesen = createAction(
    '[Result Screen Success] Updates the payment information in the store.',
  );

  public static getBelegSuggestionsSuccess = createAction(
    '[Edit Form] Get Belege suggestions succeeded.',
    props<{
      suggestionsDto: BelegSuggestionDTO,
    }>(),
  );

  public static loadBankinfoFromIban = createAction(
    '[Zahlung Effect] Load bankInfo from suggestion',
    props<{
      iban: string,
    }>(),
  );

  public static loadBankinfoFromSuggestionSuccess = createAction(
    '[Edit Form] Load bankInfo from suggestion succeeded.',
    props<{
      iban: string,
      bank: string,
      bic: string,
      blz: string | undefined,
    }>()
  );

  public static readBankSuggestions = createAction(
    '[Zahlung Effect] Get bank suggestions.',
    props<{
      inhaberId: string,
      iban: string,
    }>(),
  );

  /*
   * INFO: Zahlung Utility Actions
   */

  public static countBadgeValue = createAction(
    '[Overview] Counts the badgeValue.',
    props<{
      inhaberId: string,
    }>(),
  );

  public static addBadgeValue = createAction(
    '[Overview Effect] Adds a badgeValue.',
    props<{
      inhaberId: string,
      badgeValue: number,
    }>(),
  );

  /*
   * Utility Widget Actions
   */

  public static createWidgetHeaderData = createAction(
    '[Utility-Widget] Create widget-header-data.',
    props<{
      inhabersDtos: InhaberDTO[],
    }>(),
  );

  public static widgetHeaderDataCreated = createAction(
    '[Utility-Widget] Widget-header-data created.',
    props<{
      widgetHeaderData: WidgetHeaderData,
    }>(),
  );

  public static widgetHeaderDataUpdated = createAction(
    '[Utility-Widget] Widget-header-data updated.',
    props<{
      inhaberId: string,
      zahlungDtos: ZahlungDTO[],
    }>(),
  );

  public static readZahlungenUtilityWidget = createAction(
    '[Utility-Widget] Load payments.',
    props<{
      inhaberDto: InhaberDTO,
      pageSize: number,
      pageIndex: number,
      maturityFrom?: string,
      maturityTo: string,
    }>(),
  );

  public static readZahlungenUtilityWidgetSuccess = createAction(
    '[Utility-Widget] Read zahlungen successful.',
    props<{
      inhaberId: string,
      zahlungDtos: ZahlungDTO[],
      pageIndex: number,
      pageSize: number,
      maturity: string,
    }>(),
  );

  public static countUtilityWidgetZahlungen = createAction(
    '[Utility-Widget] Load payments count.',
    props<{
      inhaberDto: InhaberDTO,
      maturityFrom?: string,
      maturityTo: string,
    }>(),
  );

  public static zahlungCountSuccess = createAction(
    '[Utility-Widget] Load payments count successful.',
    props<{
      inhaberId: string,
      responseDto: ZahlungCountResponseDTO,
      maturity: string,
    }>(),
  );

  public static openOverviewPage = createAction(
    '[Utility-Widget] Open overview page from widget.',
    props<{
      inhaberId: string,
    }>(),
  );

  public static openEditPage = createAction(
    '[Utility-Widget] Open edit page from widget.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static forceReloadUtilityWidget = createAction(
    '[Intent Action Service] Forces a reload.',
  );

  public static logError = createAction(
    '[Zahlung] Log error.',
    props<{
      errorTitle?: string,
      error: string | MappedHttpErrorResponse,
    }>(),
  );

}
