import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';


// https://stackblitz.com/edit/angular-5mgfxh-vxdypy?file=main.ts
const germanRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 von ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;

  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} von ${length}`;
};


export function getGermanPaginatorIntl(): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Pro Seite:';
  paginatorIntl.nextPageLabel = 'Nächste Seite';
  paginatorIntl.previousPageLabel = 'Vorherige Seite';
  paginatorIntl.firstPageLabel = 'Erste Seite';
  paginatorIntl.lastPageLabel = 'Letzte Seite';
  paginatorIntl.getRangeLabel = germanRangeLabel;

  return paginatorIntl;
}
