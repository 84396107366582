import {AppState} from '../../interfaces/app-state.interface';
import {createSelector} from '@ngrx/store';


export class BelegSelectors {

  private static select = {
    belegPreviewData: (state: AppState) => state.belegReducer.belegPreviewData,
    belegCancelledDialogData: (state: AppState) => state.belegReducer.belegCancelledDialogData,
    belegFilterDialogData: (state: AppState) => state.belegReducer.belegFilterDialogData,
  };

  public static isBelegFilterDialogOpen = createSelector(
    this.select.belegFilterDialogData,
    data => data?.isBelegFilterDialogOpen,
  );

  public static loadedData = createSelector(
    this.select.belegFilterDialogData,
    data => data?.data,
  );

  public static countedData = createSelector(
    this.select.belegFilterDialogData,
    data => data?.count,
  );

  public static pagination = createSelector(
    this.select.belegFilterDialogData,
    data => data?.pagination,
  );

  public static filter = createSelector(
    this.select.belegFilterDialogData,
    data => data?.filter,
  );

  public static columns = createSelector(
    this.select.belegFilterDialogData,
    data => data?.columns,
  );

  public static sorting = createSelector(
    this.select.belegFilterDialogData,
    data => data?.sorting,
  );

  public static belegToAssignSelected = createSelector(
    this.select.belegFilterDialogData,
    data => data?.belegToAssignSelected,
  );

  /*
   * INFO: Beleg Preview Selectors
   */


  public static previewBelegDto = createSelector(
    this.select.belegPreviewData,
    data => data?.previewBelegDto,
  );

  public static iFramePreviewDialogBeleg = createSelector(
    this.select.belegPreviewData,
    data => data?.iFramePreviewDialogBeleg,
  );

  public static tryToLoadBeleg = createSelector(
    this.select.belegPreviewData,
    data => data?.tryToLoadBeleg,
  );

  public static content = createSelector(
    this.select.belegPreviewData,
    data => data?.content,
  );

  public static iFramePreviewDialogContent = createSelector(
    this.select.belegPreviewData,
    data => data?.iFramePreviewDialogContent,
  );

  public static isLoading = createSelector(
    this.select.belegPreviewData,
    data => data?.belegIsLoading,
  );

  public static isPdf = createSelector(
    this.select.belegPreviewData,
    data => data?.isPdf,
  );

  public static closeable = createSelector(
    this.select.belegPreviewData,
    data => data?.closeable,
  );

  public static highlight = createSelector(
    this.select.belegPreviewData,
    data => data?.highlight,
  );

  /*
   * INFO: Beleg Storniert Selectors
   */

  public static belegCancelledDialogInhaberId = createSelector(
    this.select.belegCancelledDialogData,
    data => data?.inhaberId,
  );

  public static belegCancelledDialogZahlungId = createSelector(
    this.select.belegCancelledDialogData,
    data => data?.zahlungId,
  );

}

