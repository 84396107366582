import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ZahlungInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private logger: NGXLogger,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status) {
          // TODO: Klären ob hier überall zwischen verschiedenen Services unterschieden werden muss.
          switch (error.status) {
            case 502:
              this.logger.error('service unavailable');

              const errorUrl = '/error/service-unavailable';
              if (this.router.url !== errorUrl) {
                this.router.navigate([errorUrl]);
              }

              return EMPTY;
              break;
            case 503:
            // FIXME: Http 503 Fehler behandeln. Zwischen verschiedenen Service unterscheiden.
          }
        }

        return throwError(error);
      })
    );
  }
}
