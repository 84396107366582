import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


type IconNamespace = 'common' | 'pdf-viewer' | 'status';

interface IconSpec {
  namespace: IconNamespace;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class IconService {
  iconList: IconSpec[] = [
    {
      namespace: 'common',
      name: 'folder',
    },
    {
      namespace: 'common',
      name: 'folder-dashed',
    },
    {
      namespace: 'common',
      name: 'close',
    },
    {
      namespace: 'common',
      name: 'gear',
    },
    {
      namespace: 'common',
      name: 'add_plus',
    },
    {
      namespace: 'common',
      name: 'pay',
    },
    {
      namespace: 'common',
      name: 'delete',
    },
    {
      namespace: 'common',
      name: 'error_circle',
    },
    {
      namespace: 'common',
      name: 'arrow_left',
    },
    {
      namespace: 'common',
      name: 'arrow_right',
    },
    {
      namespace: 'common',
      name: 'arrow_reload',
    },
    {
      namespace: 'common',
      name: 'info',
    },
    {
      namespace: 'common',
      name: 'broken_chain_link',
    },
    {
      namespace: 'common',
      name: 'circled_plus',
    },
    {
      namespace: 'common',
      name: 'done_mark',
    },
    {
      namespace: 'common',
      name: 'done_mark_small',
    },
    {
      namespace: 'common',
      name: 'sad_smiley',
    },
    {
      namespace: 'common',
      name: 'balance',
    },
    {
      namespace: 'common',
      name: 'balance_retrieve',
    },
    {
      namespace: 'common',
      name: 'not_editable',
    },
    {
      namespace: 'common',
      name: 'beleg-cancelled',
    },
    {
      namespace: 'common',
      name: 'reassign_beleg',
    },
    {
      namespace: 'common',
      name: 'eye',
    },
    {
      namespace: 'common',
      name: 'dot',
    },
    {
      namespace: 'common',
      name: 'calendar-urgend',
    },
    {
      namespace: 'common',
      name: 'calendar',
    },
    {
      namespace: 'common',
      name: 'utility-widget-empty-state',
    },
    {
      namespace: 'common',
      name: 'access_error',
    },
    {
      namespace: 'pdf-viewer',
      name: 'search',
    },
    {
      namespace: 'pdf-viewer',
      name: 'download',
    },
    {
      namespace: 'pdf-viewer',
      name: 'print',
    },
    {
      namespace: 'status',
      name: 'open',
    },
    {
      namespace: 'status',
      name: 'instructed',
    },
    {
      namespace: 'status',
      name: 'stapled',
    },
    {
      namespace: 'status',
      name: 'cancelled',
    },
    {
      namespace: 'status',
      name: 'edited',
    },
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList.forEach(icon => {
      iconRegistry.addSvgIconInNamespace(
        icon.namespace,
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.namespace}/${icon.name}.svg`)
      );
    });
  }
}
