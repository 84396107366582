import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ZahlungDTO} from '../../openapi/zahlung-openapi';
import {Params} from '@angular/router';
import {AppState} from '../../interfaces/app-state.interface';
import {AppContextSelectors} from '../../store/selectors/app-context.selectors';
import {TransactionSelectors} from '../../store/selectors/transaction.selectors';
import {TransactionActions} from '../../store/actions/transaction.actions';
import {InhaberEntitiesSelectors} from '@adnova/jf-ng-components';


export interface WizardActionButton {
  title: string;
  href: string;
  icon?: string;
  logout?: boolean;
  showInPopup?: boolean;
  queryParams?: Params;
}

@Component({
  selector: 'zlng-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardTemplateComponent implements OnInit, OnDestroy {

  public popupMode?: boolean;

  public inhaberId = '';

  // tslint:disable-next-line:variable-name
  public _actionButtons: WizardActionButton[] = [];

  public finalPayments: ZahlungDTO[] = [];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.store.select(TransactionSelectors.finalPayments).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(finalPayments => {
      this.finalPayments = finalPayments || [];
    });

    this.store.select(AppContextSelectors.wizardActionButtons).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(actionButtons => {
      this._actionButtons = actionButtons || [];
    });

    this.store.select(AppContextSelectors.popupMode).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(popupMode => {
      this.popupMode = popupMode;
    });

    this.store.select(InhaberEntitiesSelectors.currentInhaber).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if(!inhaber?.id) return;
      this.inhaberId = inhaber?.id;
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /*
   * ActionButtons nur anzeigen, wenn wir NICHT im Popup-Modus sind, oder wenn diese
   * für den Popup-Modus freigegeben wurden. "undefined" wird als default im Popup angezeigt.
   */
  get actionButtons(): WizardActionButton[] {
    return this._actionButtons.filter(actionButton =>
      !this.popupMode || actionButton.showInPopup || actionButton.showInPopup === undefined
    );
  }

  /*
   * Header anzeigen, wenn wegen Sammelüberweisung die Navigation eingeblendet wird
   * oder ActionButtons angezeigt werden sollen.
   */
  get showHeader(): boolean {
    return !!this.finalPayments.length || !!this.actionButtons.length;
  }

  /**
   * Wenn logout = true, werden die Informationen über das bestehende Login des Users aus dem Store entfernt.
   *
   * @param logout Kennzeichnen ob ausgeloggt werden soll
   */
  doLogout(logout?: boolean): void {
    if (logout) {
      this.store.dispatch(TransactionActions.logout());
    }
  }
}
