import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {BelegDTO, BelegService as Service} from '../openapi/beleg-openapi';


@Injectable({
  providedIn: 'root'
})
export class BelegService {

  constructor(
    private logger: NGXLogger,
    private belegService: Service,
  ) {
  }

  getBeleg(inhaberId: string, belegId: string): Observable<BelegDTO> {
    return this.belegService.getBeleg(inhaberId, belegId);
  }
}
