<div class="spinner-wrapper d-flex justify-content-center align-items-center" *ngIf="loading">
  <mat-spinner [diameter]="50"
               [color]="'accent'"
  ></mat-spinner>
</div>

<div class="preview-component" *ngIf="!loading">

  <ng-container *ngIf="isPdf !== undefined">
    <ng-container *ngIf="isPdf; else downloadPreview">
      <zlng-pdf-preview [closeable]="closeable" (closePreview)="doClosePreview()"></zlng-pdf-preview>
    </ng-container>

    <ng-template #downloadPreview>
      <zlng-download-preview [closeable]="closeable" (closePreview)="doClosePreview()"></zlng-download-preview>
    </ng-template>
  </ng-container>

</div>
