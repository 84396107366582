<ng-container *ngIf="hasBeleg !== undefined">

  <!-- Wenn ein Beleg hinterlegt ist -->
  <ng-container *ngIf="hasBeleg">
    <zlng-header-actions
      *ngIf="!popupMode && !angewiesen && !isNotEditable"
      [inTransactionWizard]="inTransactionWizard"
      (assignNewBelegEvent)="openBelegChooser()"
      (removeBelegAssociationEvent)="removeBelegAssociation()"
    ></zlng-header-actions>
    <zlng-preview></zlng-preview>
  </ng-container>

  <!-- Wenn KEIN Beleg hinterlegt ist -->
  <ng-container *ngIf="!hasBeleg">

    <!-- Wenn der Wizard bereits gestartet wurde -->
    <ng-container *ngIf="inTransactionWizard || angewiesen">
      <zlng-beleglos-locked>
      </zlng-beleglos-locked>
    </ng-container>

    <!-- Wenn der Wizard noch nicht gestartet wurde -->
    <ng-container *ngIf="!inTransactionWizard && !angewiesen">
      <zlng-beleglos
        (openBelegChooser)="openBelegChooser()">
      </zlng-beleglos>
    </ng-container>

  </ng-container>
</ng-container>
