export function getDefaultColor(): string {
  return accountColors.filter(color => color.colorDigits.indexOf(0) >= 0)[0].color;
}

export function getColorByIban(iban?: string): string {
  if (iban && iban.length === 22) {
    const colorDigit = Number(iban.substring(7, 8)) || 0;
    return accountColors.filter(color => color.colorDigits.indexOf(colorDigit) >= 0)[0].color;
  } else {
    return getDefaultColor();
  }
}

export function getColorByBlz(blz?: string): string {
  if (blz) {
    if (blz.length === 8) {
      const colorDigit = Number(blz.slice(3, 4)) || 0;
      return accountColors.filter(color => color.colorDigits.indexOf(colorDigit) >= 0)[0].color;
    } else {
      return getDefaultColor();
    }
  } else {
    return getDefaultColor();
  }
}

export interface AccountColor {
  colorDigits: number[];
  bankNames: string[];
  color: string;
}

export const accountColors: AccountColor[] = [
  {
    colorDigits: [0],
    bankNames: ['Default', 'Deutsche Bundesbank'],
    color: '#B7B7B764',
  },
  {
    // Kreditinstitute und Postbank
    colorDigits: [1],
    bankNames: ['Postbank'],
    color: '#FFCC0064',
  },
  {
    // Kreditinstitute; u.A. Unicredit Bank, Hypovereinsbank, ...
    colorDigits: [2, 3],
    bankNames: ['Unicredit', 'Hypovereinsbank'],
    color: '#C70F1364',
  },
  {
    // Commerzbank, früher: Dresdner Bank und ihre Tochterinstitute
    colorDigits: [4, 8],
    bankNames: ['Commerzbank', 'Dresdner Bank'],
    color: '#FBCD3E64',
  },
  {
    // Sparkasse
    colorDigits: [5],
    bankNames: ['Sparkasse'],
    color: '#E4555564',
  },
  {
    // Volksbank, Raiffeisenbank
    colorDigits: [6, 9],
    bankNames: ['Volksbank', 'Raiffeisenbank'],
    color: '#F6904C64',
  },
  {
    // Deutsche Bank
    colorDigits: [7],
    bankNames: ['Deutsche Bank'],
    color: '#4A5CFF64',
  },
];
