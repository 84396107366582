import {createAction, props} from '@ngrx/store';
import {Extras} from '@sentry/types/types/extra';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';

export class SentryActions {

  public static captureException = createAction(
    '[Sentry] Captures the exception and sends it so sentry.',
    props<{
      error: MappedHttpErrorResponse,
      extras: Extras,
    }>(),
  );

}
